import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { fetchSalesByHours } from "../../../../Redux/features/Reports/SalesByHours/SalesByHoursSlice";
import { useAuthDetails } from "../../../../Common/cookiesHelper";
import { priceFormate } from "../../../../hooks/priceFormate";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import PasswordShow from "../../../../Common/passwordShow";
import BarCharts from "../../../Dashboard/BarCharts";
import NoDataFound from "../../../../reuseableComponents/NoDataFound";
import Skeleton from "react-loading-skeleton";
import { Grid } from "@mui/material";
import { excelValue, formatCurrency } from "../../../../Constants/utils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#253338",
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StickyTableCell = styled(StyledTableCell)(({ theme }) => ({
  position: "sticky",
  left: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 1,
  // borderRight: "1px solid #444E51 !important;"
}));

const StickyTableCellR = styled(StyledTableCell)(({ theme }) => ({
  position: "sticky",
  right: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 1,
  // borderLeft: "1px solid #444E51 !important;"
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const SalesbyTable = (props) => {
  const dispatch = useDispatch();
  const {
    LoginGetDashBoardRecordJson,
    LoginAllStore,
    userTypeData,
    GetSessionLogin,
  } = useAuthDetails();

  const { handleCoockieExpire, getUnAutherisedTokenMessage, getNetworkError } =
    PasswordShow();

  const [allshiftsummary, setAllShiftSummary] = useState([]);
  const allshiftsummaryDataState = useSelector(
    (state) => state.SalesByHoursData
  );
  let merchant_id = LoginGetDashBoardRecordJson?.data?.merchant_id;

  const getSalesByHoursReportData = async () => {
    if (props && props.selectedDateRange) {
      try {
        const startDateData = props.selectedDateRange.start_date;
        const endDateData = props.selectedDateRange.end_date;
        let data = {
          merchant_id,
          start_date: startDateData,
          end_date: endDateData,
          ...userTypeData,
        };

        if (data) {
          await dispatch(fetchSalesByHours(data)).unwrap();
        }
      } catch (error) {
        console.log(error);
        if (error.status == 401) {
          getUnAutherisedTokenMessage();
          handleCoockieExpire();
        }
      }
    }
  };

  useEffect(() => {
    getSalesByHoursReportData();
  }, [props.selectedDateRange]);

  const allTotalsZero = useMemo(() => {
    const totals = allshiftsummary?.totals
      ? Object.values(allshiftsummary?.totals).every((value) => value === 0)
      : true;

    return totals;
  }, [allshiftsummary?.totals]);

  useEffect(() => {
    if (
      !allshiftsummaryDataState?.loading &&
      allshiftsummaryDataState?.SalesByHoursData
    ) {
      console.log(
        "allshiftsummaryDataState.SalesByHoursData: ",
        allshiftsummaryDataState.SalesByHoursData
      );

      if (allshiftsummaryDataState.SalesByHoursData) {
        setAllShiftSummary(allshiftsummaryDataState.SalesByHoursData);

        const time = [
          {
            label: "12AM - 1AM",
            key: "00:00",
          },
          {
            label: "1AM - 2AM",
            key: "01:00",
          },
          {
            label: "2AM - 3AM",
            key: "02:00",
          },
          {
            label: "3AM - 4AM",
            key: "03:00",
          },
          {
            label: "4AM - 5AM",
            key: "04:00",
          },
          {
            label: "5AM - 6AM",
            key: "05:00",
          },
          {
            label: "6AM - 7AM",
            key: "06:00",
          },
          {
            label: "7AM - 8AM",
            key: "07:00",
          },
          {
            label: "8AM - 9AM",
            key: "08:00",
          },
          {
            label: "9AM - 10AM",
            key: "09:00",
          },
          {
            label: "10AM - 11AM",
            key: "10:00",
          },
          {
            label: "11AM - 12PM",
            key: "11:00",
          },
          {
            label: "12PM - 1PM",
            key: "12:00",
          },
          {
            label: "1PM - 2PM",
            key: "13:00",
          },
          {
            label: "2PM - 3PM",
            key: "14:00",
          },
          {
            label: "3PM - 4PM",
            key: "15:00",
          },
          {
            label: "4PM - 5PM",
            key: "16:00",
          },
          {
            label: "5PM - 6PM",
            key: "17:00",
          },
          {
            label: "6PM - 7PM",
            key: "18:00",
          },
          {
            label: "7PM - 8PM",
            key: "19:00",
          },
          {
            label: "8PM - 9PM",
            key: "20:00",
          },
          {
            label: "9PM - 10PM",
            key: "21:00",
          },
          {
            label: "10PM - 11PM",
            key: "22:00",
          },
          {
            label: "11PM - 12AM",
            key: "23:00",
          },
        ];

        const headers = [
          {
            label: "Hours",
            key: "name",
          },
          ...time,
          {
            label: "Total",
            key: "total",
          },
        ];

        console.log("headers: ", headers);

        console.log(
          "allshiftsummaryDataState?.SalesByHoursData: ",
          allshiftsummaryDataState?.SalesByHoursData
        );

        const newData = [
          {
            name: "Sales Count",
            // sales_count: 0,
            key: "sales_count",
            total: excelValue(
              allshiftsummaryDataState?.SalesByHoursData?.totals
                ?.total_sales_count
            ),
          },
          {
            name: "Net sales",
            // net_sales: 0,
            key: "net_sales",
            total: excelValue(
              allshiftsummaryDataState?.SalesByHoursData?.totals
                ?.total_net_sales,
              "dollar"
            ),
          },
          {
            name: "Cost of good sold",
            // cost_price: 0,
            key: "cost_price",
            total: excelValue(
              allshiftsummaryDataState?.SalesByHoursData?.totals
                ?.total_cost_price,
              "dollar"
            ),
          },
          {
            name: "Gross profit",
            // gross_profit: 0,
            key: "gross_profit",
            total: excelValue(
              allshiftsummaryDataState?.SalesByHoursData?.totals
                ?.total_gross_profit,
              "dollar"
            ),
          },
          {
            name: "Margin (%)",
            // margin: 0,
            key: "margin",
            total: excelValue(
              allshiftsummaryDataState?.SalesByHoursData?.totals?.total_margin,
              "percent"
            ),
          },
          {
            name: "Tax",
            // tax: 0,
            key: "tax",
            total: excelValue(
              allshiftsummaryDataState?.SalesByHoursData?.totals?.total_tax,
              "dollar"
            ),
          },
        ];

        if (allshiftsummaryDataState?.SalesByHoursData.hoursData) {
          const csvData = newData.map((item, index) => {
            const timeValues = time.map((t) => t.key);

            const newKeys = {};
            timeValues.forEach((val) => {
              const value =
                allshiftsummaryDataState?.SalesByHoursData.hoursData[val][
                  item.key
                ];

              newKeys[val] =
                item.key === "sales_count"
                  ? value
                  : item.key === "margin"
                    ? `${parseFloat(value).toFixed(2)}`
                    : `${priceFormate(
                        formatCurrency(parseFloat(value).toFixed(2))
                      )}`;
            });
            return {
              ...item,
              ...newKeys,
            };
          });

          console.log("csvData: ", csvData);

          if (!allTotalsZero) {
            props.setCSVHeader(headers);
            props.setCSVData(csvData);
          } else {
            props.setCSVHeader(null);
            props.setCSVData(null);
          }
        }
      }
    }
  }, [allshiftsummaryDataState, allTotalsZero]);

  return (
    <>
      <BarCharts
        graphData={allshiftsummary?.hoursData && allshiftsummary?.hoursData}
      />
      <div className=" mb-12">
        {allshiftsummaryDataState?.loading ? (
          <>
            <Grid container className="box_shadow_div" sx={{ mt: 3.5, p: 2.5 }}>
              <Grid item xs={12}>
                <Skeleton height={200} width={"100%"} />
              </Grid>
            </Grid>
          </>
        ) : (
          <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
            <div
              style={{ overflowX: "auto", WebkitOverflowScrolling: "touch" }}
            >
              <Table sx={{ minWidth: 500 }} aria-label="shift summary table">
                <TableHead>
                  <TableRow>
                    <StickyTableCell
                      className="whitespace-nowrap salebyhourHeading BORHeaderRight"
                      sx={{ width: "10.66%" }}
                    >
                      Hours
                    </StickyTableCell>
                    <StyledTableCell className="whitespace-nowrap  ">
                      12AM - 1AM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      1AM - 2AM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      2AM - 3AM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      3AM - 4AM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      4AM - 5AM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      5AM - 6AM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      6AM - 7AM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      7AM - 8AM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      8AM - 9AM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      9AM - 10AM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      10AM - 11AM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      11AM - 12PM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      12PM - 1PM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      1PM - 2PM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      2PM - 3PM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      3PM - 4PM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      4PM - 5PM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      5PM - 6PM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      6PM - 7PM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      7PM - 8PM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      8PM - 9PM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      9PM - 10PM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      10PM - 11PM
                    </StyledTableCell>
                    <StyledTableCell className="whitespace-nowrap">
                      11PM - 12AM
                    </StyledTableCell>
                    <StickyTableCellR className="whitespace-nowrap BORHeaderLeft">
                      Total
                    </StickyTableCellR>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!allTotalsZero && (
                    <>
                      <TableRow>
                        <StickyTableCell className="whitespace-nowrap BORBodyRight">
                          Sales Count
                        </StickyTableCell>
                        {allshiftsummary?.hoursData &&
                          Object.keys(allshiftsummary?.hoursData).map(
                            (time, index) => (
                              <StyledTableCell
                                key={index}
                                className="whitespace-nowrap"
                              >
                                {priceFormate(
                                  allshiftsummary?.hoursData[time]?.sales_count
                                )}
                              </StyledTableCell>
                            )
                          )}
                        <StickyTableCellR className="whitespace-nowrap BORBodyLeft">
                          {priceFormate(
                            allshiftsummary?.totals?.total_sales_count
                          )}
                        </StickyTableCellR>
                      </TableRow>
                      <TableRow>
                        <StickyTableCell className="whitespace-nowrap BORBodyRight">
                          Net Sales{" "}
                        </StickyTableCell>
                        {allshiftsummary?.hoursData &&
                          Object.keys(allshiftsummary?.hoursData).map(
                            (time, index) => (
                              <StyledTableCell
                                key={index}
                                className="whitespace-nowrap"
                              >
                                {formatCurrency(
                                  Number(
                                    allshiftsummary?.hoursData[time]?.net_sales
                                  ).toFixed(2)
                                )}
                              </StyledTableCell>
                            )
                          )}
                        <StickyTableCellR className="whitespace-nowrap BORBodyLeft">
                          {formatCurrency(
                            allshiftsummary?.totals?.total_net_sales.toFixed(2)
                          )}
                        </StickyTableCellR>
                      </TableRow>
                      <TableRow>
                        <StickyTableCell className="whitespace-nowrap BORBodyRight">
                          Cost of good sold
                        </StickyTableCell>
                        {allshiftsummary?.hoursData &&
                          Object.keys(allshiftsummary?.hoursData).map(
                            (time, index) => (
                              <StyledTableCell
                                key={index}
                                className="whitespace-nowrap"
                              >
                                {formatCurrency(
                                  Number(
                                    allshiftsummary?.hoursData[time]?.cost_price
                                  ).toFixed(2)
                                )}
                              </StyledTableCell>
                            )
                          )}
                        <StickyTableCellR className="whitespace-nowrap BORBodyLeft">
                          {formatCurrency(
                            allshiftsummary?.totals?.total_cost_price.toFixed(2)
                          )}
                        </StickyTableCellR>
                      </TableRow>
                      <TableRow>
                        <StickyTableCell className="whitespace-nowrap BORBodyRight">
                          Gross profit
                        </StickyTableCell>
                        {allshiftsummary?.hoursData &&
                          Object.keys(allshiftsummary?.hoursData).map(
                            (time, index) => (
                              <StyledTableCell
                                key={index}
                                className="whitespace-nowrap"
                              >
                                {formatCurrency(
                                  Number(
                                    allshiftsummary?.hoursData[time]
                                      ?.gross_profit
                                  ).toFixed(2)
                                )}
                              </StyledTableCell>
                            )
                          )}
                        <StickyTableCellR className="whitespace-nowrap BORBodyLeft">
                          {formatCurrency(
                            allshiftsummary?.totals?.total_gross_profit.toFixed(
                              2
                            )
                          )}
                        </StickyTableCellR>
                      </TableRow>
                      <TableRow>
                        <StickyTableCell className="whitespace-nowrap BORBodyRight">
                          Margin (%)
                        </StickyTableCell>
                        {allshiftsummary?.hoursData &&
                          Object.keys(allshiftsummary?.hoursData).map(
                            (time, index) => (
                              <StyledTableCell
                                key={index}
                                className="whitespace-nowrap"
                              >
                                {priceFormate(
                                  allshiftsummary?.hoursData[
                                    time
                                  ]?.margin.toFixed(2)
                                )}
                              </StyledTableCell>
                            )
                          )}
                        <StickyTableCellR className="whitespace-nowrap BORBodyLeft">
                          {priceFormate(
                            allshiftsummary?.totals?.total_margin.toFixed(2)
                          )}
                          %
                        </StickyTableCellR>
                      </TableRow>
                      <TableRow>
                        <StickyTableCell className="whitespace-nowrap BORBodyRight">
                          Tax
                        </StickyTableCell>
                        {allshiftsummary?.hoursData &&
                          Object.keys(allshiftsummary?.hoursData).map(
                            (time, index) => (
                              <StyledTableCell
                                key={index}
                                className="whitespace-nowrap"
                              >
                                {formatCurrency(
                                  Number(
                                    allshiftsummary?.hoursData[time]?.tax
                                  ).toFixed(2)
                                )}
                              </StyledTableCell>
                            )
                          )}

                        <StickyTableCellR className="whitespace-nowrap BORBodyLeft">
                          {formatCurrency(
                            allshiftsummary?.totals?.total_tax.toFixed(2)
                          )}
                        </StickyTableCellR>
                      </TableRow>
                    </>
                  )}

                  {allTotalsZero && (
                    <>
                      <div
                        style={{
                          width: "15rem",
                          position: "sticky",
                          left: "45%",
                        }}
                      >
                        <NoDataFound table={true} />
                      </div>
                    </>
                  )}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
        )}
      </div>
    </>
  );
};

export default SalesbyTable;
