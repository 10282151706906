import { createSlice } from "@reduxjs/toolkit";

const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem("menustate");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.log("Error loading state from sessionStorage", err);
  }
};

// Define initial state
const initialState = loadState() || {
  isMenuOpen: true, // Initial value based on your useState
  isDropdownOpen: false,
  isNestedDropdown: false,
  isStoreActive: false,
  isMobileView: false,
  isOpenedByHover: false,
};
// Create a slice
const MenuSlice = createSlice({
  name: "menu",
  initialState,

  reducers: {
    // Reducer function for toggling menu
    toggleMenu: (state) => {
      state.isMenuOpen = !state.isMenuOpen;
    },
    // Reducer function for setting menu state
    setMenuOpen: (state, action) => {
      state.isMenuOpen = action.payload;
      try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem("menustate", serializedState);
      } catch (err) {
        console.error("Error saving state to sessionStorage", err);
      }
    },
    setIsDropdownOpen: (state, action) => {
      state.isDropdownOpen = action.payload;
    },
    setNestedDropdown: (state, action) => {
      state.isNestedDropdown = action.payload;
    },
    setIsStoreActive: (state, action) => {
      state.isStoreActive = action.payload;
    },
    setIsMobileViewActive: (state, action) => {
      state.isMobileView = action.payload;
    },
    setIsOpenedByHover: (state, action) => {
      state.isOpenedByHover = action.payload;
    },
  },
});

// Export actions
export const {
  toggleMenu,
  setMenuOpen,
  setIsDropdownOpen,
  setNestedDropdown,
  setIsStoreActive,
  setIsMobileViewActive,
  setIsOpenedByHover,
} = MenuSlice.actions;

// Export reducer
export default MenuSlice.reducer;
