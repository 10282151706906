import React, { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DashboardIcon from "../../Assests/Dashboard/dashboard.svg";
import ShoppingCartIcon from "../../Assests/Dashboard/orders.svg";
import CategoryIconActive from "../../Assests/Dashboard/category.svg";
import Inventory_Active_Icon from "../../Assests/Dashboard/Inventory_Active_Icon.svg";
import Inventory_Icon from "../../Assests/Dashboard/Inventory_Icon.svg";
import Employees_Active_Icon from "../../Assests/Dashboard/Employees_Active_Icon.svg";
import Employees_Icon from "../../Assests/Dashboard/Employees_Icon.svg";
import CouponIcon from "../../Assests/Dashboard/coupanb.svg";
import Tax_Active_Icon from "../../Assests/Dashboard/Tax_Active_Icon.svg";
import Tax_Icon from "../../Assests/Dashboard/Tax_Icon.svg";
import AttributesIcon from "../../Assests/Dashboard/attributesadmin.svg";
import PurchaseIcon from "../../Assests/Dashboard/purchesb.svg";
import Stock_take_Active_Icon from "../../Assests/Dashboard/Stock_take_Active_Icon.svg";
import Stock_take_Icon from "../../Assests/Dashboard/Stock_take_Icon.svg";
import ProductIcon from "../../Assests/Dashboard/productb.svg";
import dataIcon from "../../Assests/Dashboard/vender.svg";

import TimesheetsIcon from "../../Assests/Dashboard/timesheetb.svg";

import StoreSettingIcon from "../../Assests/Dashboard/store.svg";
import ReportIcon from "../../Assests/Dashboard/reporting.svg";
import DashIcon from "../../Assests/Dashboard/dashIcon.svg";
import OrderYellow from "../../Assests/Dashboard/ordery.svg";
import CatIcon from "../../Assests/Dashboard/categoryd.svg";
import AtriIcon from "../../Assests/Dashboard/attributesy.svg";
import ProdIcon from "../../Assests/Dashboard/productY.svg";
import VenIcon from "../../Assests/Dashboard/venderb.svg";
import Vendor_Active_icon from "../../Assests/Dashboard/Vendor_Active_icon.svg";
import Vendor_Icon from "../../Assests/Dashboard/Vendor_Icon.svg";
import VenIconActive from "../../Assests/Dashboard/vendery.svg";
import DataIconActive from "../../Assests/Dashboard/importy.svg";
import CouIcon from "../../Assests/Dashboard/coupony.svg";
import MixMatchIcon from "../../Assests/Dashboard/mix-match-icon.svg";
import MixMatchIcon1 from "../../Assests/Dashboard/mix-match-icon-1.svg";
import PurIcon from "../../Assests/Dashboard/purchaseY.svg";
import SettingIcon from "../../Assests/Dashboard/settingY.svg";
import ResportIcons from "../../Assests/Dashboard/reports.svg";
import timesheetblackIcon from "../../Assests/Dashboard/timesheetblackIcon.svg";
import Loyalty from "../../Assests/Taxes/Loyalty Program.svg";
import LoyaltIcon from "../../Assests/Taxes/loyaltyactive.svg";
import { useMediaQuery } from "@mui/material";
import {
  setMenuOpen,
  setIsDropdownOpen,
  setIsMobileViewActive,
  setIsOpenedByHover,
} from "../../Redux/features/NavBar/MenuSlice";
import { useSelector, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { useAuthDetails } from "../../Common/cookiesHelper";

import LabelIcon from "../../Assests/MultipleUserIcon/label.svg";
import LabelActive from "../../Assests/MultipleUserIcon/labelactive.svg";
import UsersICcon from "../../Assests/MultipleUserIcon/user.svg";
import UserActive from "../../Assests/MultipleUserIcon/useractive.svg";
import NewsletterIcon from "../../Assests/MultipleUserIcon/newsletter.svg";
import NewsletterActive from "../../Assests/MultipleUserIcon/newsletteractive.svg";
import StoreIcon from "../../Assests/MultipleUserIcon/store.svg";
import StoreActive from "../../Assests/MultipleUserIcon/storeactive.svg";
import OrderIcon from "../../Assests/MultipleUserIcon/orderCount.svg";
import OrderActive from "../../Assests/MultipleUserIcon/ordercountactive.svg";
import DefaultIcon from "../../Assests/MultipleUserIcon/defaults.svg";
import DefaultActive from "../../Assests/MultipleUserIcon/defaultactive.svg";
import ApkIcon from "../../Assests/MultipleUserIcon/apk.svg";
import ApkActive from "../../Assests/MultipleUserIcon/apkactive.svg";
import DuplicatesIcon from "../../Assests/MultipleUserIcon/duplicates.svg";
import DuplicatesActive from "../../Assests/MultipleUserIcon/duplicatesactive.svg";
import PermissionIcon from "../../Assests/MultipleUserIcon/permission.svg";
import PermissionActive from "../../Assests/MultipleUserIcon/permissionactive.svg";
import InverntoryIcon from "../../Assests/MultipleUserIcon/inventory.svg";
import InvertoryActive from "../../Assests/MultipleUserIcon/inventoryactive.svg";
import storeIcon from "../../Assests/Manager/storeIcon.svg";
import storeIconActive from "../../Assests/Manager/storeIconActive.svg";
import ManagerIcon from "../../Assests/Manager/managerIcon.svg";
import ManagerIconActive from "../../Assests/Manager/managerIconActive.svg";

import MerchantIcon from "../../Assests/MultipleUserIcon/merchant.svg";
import MerchantActive from "../../Assests/MultipleUserIcon/merchantactive.svg";
import NestedDropdownMenu from "./NestedDropdownMenu";
import { BASE_URL } from "../../Constants/Config";
import Support from "../../Assests/Category/Support.svg";
import Support1 from "../../Assests/Category/Support-1.svg";
import OrderRetrieve from "../../Assests/OrderRetireve/Order_retrive.svg";
import OrderRetrievey from "../../Assests/OrderRetireve/Order_retrivey.svg";
import { generateClass } from "../../Constants/utils";

const SideMenu = () => {
  const { LoginGetDashBoardRecordJson, LoginAllStore, userTypeData } =
    useAuthDetails();

  const [menuItemSwitcher, setMenuItemSwitcher] = useState(menuItems);

  const temp = {
    superadmin: menuItemSwitcher,
    admin: merchant,
    manager: ManagerLink,
    merchant: MerchantLink,
  };

  const location = useLocation();
  const currentUrl = location.pathname;
  const isMenuOpenRedux = useSelector((state) => state.NavBarToggle.isMenuOpen);

  const isDropdownOpen = useSelector(
    (state) => state.NavBarToggle.isDropdownOpen
  );
  const isStoreActive = useSelector(
    (state) => state.NavBarToggle.isStoreActive
  );
  const isMobileViewActive = useSelector(
    (state) => state.NavBarToggle.isMobileView
  );
  const isOpenedByHover = useSelector(
    (state) => state.NavBarToggle.isOpenedByHover
  );

  const [activeItem, setActiveItem] = useState(currentUrl);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [currentDropDownItem, activeDropDownItem] = useState(null);
  const [activeNestedItem, setActiveNestedItem] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isTabletNav = useMediaQuery("(max-width:1333px)");
  const isMobileNav = useMediaQuery("(min-width:767px)");
  const isMobileNavtest = useMediaQuery("(max-width: 768px)");
  const handleItemClick = (item) => {
    if (isTabletNav) {
      dispatch(setIsDropdownOpen(false));
      dispatch(setMenuOpen(false));
      dispatch(setIsMobileViewActive(false));
      setHoveredItem(null);
    }
    setActiveItem(item.link);
    navigate(item.link);
    setActiveNestedItem(null);
    // dispatch(setIsDropdownOpen(false))
    activeDropDownItem(null);
    // dispatch(setIsMobileViewActive(false))
  };

  // console.log("active item", activeItem)

  // useEffect(() => {
  //   dispatch(setMenuOpen(!isTabletNav));
  //   dispatch(setIsMobileViewActive(false));
  // }, [isTabletNav, isMobileNav]);
  useEffect(() => {
    // Set the menu open state based on screen width (tablet or mobile view)
    // But also don't override the user manually toggling the menu
    if (isTabletNav) {
      dispatch(setMenuOpen(false)); // Open the menu for smaller screens
      dispatch(setIsDropdownOpen(false));
    }
    dispatch(setIsMobileViewActive(false));
  }, [isTabletNav, isMobileNav]);

  useEffect(() => {
    setActiveItem(currentUrl);
    // if (currentUrl.split("/")[1] === "users") {
    //   setMenuItemSwitcher(SuperAdminMenuItems);
    //   dispatch(setMenuOpen(true));
    // } else {
    //   setMenuItemSwitcher(menuItems);
    // }
    // console.log("setIsStoreActive setIsStoreActive0,",isStoreActive)
    if (!isStoreActive) {
      setMenuItemSwitcher(SuperAdminMenuItems);
      // dispatch(setMenuOpen(true));
    } else {
      const updatedMenuItems = [...menuItems];

      if (LoginGetDashBoardRecordJson?.login_type === "superadmin") {
        const newMenuItem = {
          id: 91,
          icon: (
            <img
              src={dataIcon}
              alt="Export Sold Items"
              className="h-6 w-10 mt-4 mb-4 hoverable-image"
            />
          ),
          activeIcon: (
            <img
              src={DataIconActive}
              alt="Import"
              className="h-6 w-10 mt-4 mb-4"
            />
          ),
          text: "Import Data",
          link: "/import-data",
        };
        // console.log("updatedMenuItems",updatedMenuItems)
        updatedMenuItems.splice(updatedMenuItems.length - 1, 0, newMenuItem);
        const newMenuItemDigit = {
          id: 92,
          icon: (
            <img
              src={dataIcon}
              alt="MainDigitalMarketing"
              className="h-6 w-10 mt-4 mb-4 hoverable-image"
            />
          ),
          activeIcon: (
            <img
              src={DataIconActive}
              alt="MainDigitalMarketing"
              className="h-6 w-10 mt-4 mb-4"
            />
          ),
          text: "Digital Marketing Tags",
          link: "/Digital-marketing-tags",
        };
        const newregistersetting = {
          id: 69,
          icon: (
            <img
              src={AttributesIcon}
              alt="inventory-merge"
              className="h-6 w-10 mt-4 mb-4 hoverable-image"
            />
          ),
          activeIcon: (
            <img
              src={AtriIcon}
              alt="atributes"
              className="h-6 w-10 mt-4 mb-4"
            />
          ),
          text: "Register Settings",
          link: "/store-settings/register",
        };

        const newUpdateInventoryMerge = [
          {
            id: 1726725862759,
            icon: (
              <img
                src={AttributesIcon}
                alt="Lottery"
                className="h-6 w-10 mt-4 mb-4 hoverable-image"
              />
            ),
            activeIcon: (
              <img
                src={AtriIcon}
                alt="Lottery"
                className="h-6 w-10 mt-4 mb-4"
              />
            ),
            text: "Lottery",
            link: "/inventory/lottery",
          },
          {
            id: 15,
            icon: (
              <img
                src={AttributesIcon}
                alt="inventory-merge"
                className="h-6 w-10 mt-4 mb-4 hoverable-image"
              />
            ),
            activeIcon: (
              <img
                src={AtriIcon}
                alt="atributes"
                className="h-6 w-10 mt-4 mb-4"
              />
            ),
            text: "Inventory Merge",
            link: "/inventory-merge",
          },
        ];
        const updatedReportingForSuperAdmin = [
          // {
          //   id: 3,
          //   text: "Employee Reports",
          //   dropDownItems: [
          //     {
          //       id: 72,
          //       text: "Employee List",
          //       link: "/store-reporting/employee-list",
          //     },
          //     {
          //       id: 75,
          //       text: "Employee Working Hours",
          //       link: "/store-reporting/employee-working-hours",
          //     },
          //     {
          //       id: 89,
          //       text: "Discount Per Sales Person",
          //       link: "/store-reporting/discount-per-sales-report",
          //     },
          //   ],
          // },
          // {
          //   id: 1723011716338,
          //   text: "Customer Reports",
          //   dropDownItems: [
          //     {
          //       id: 103,
          //       text: "New Customers Added Report",
          //       link: "/store-reporting/new-customers-added-report",
          //     },
          //     // {
          //     //   id: 101,
          //     //   text: "Detailed Loyalty Points Report",
          //     //   link: "/store-reporting/detailed-loyalty-points-report",
          //     // },
          //   ],
          // },
          {
            id: 5,
            text: "Vendors",
            dropDownItems: [
              {
                id: 71,
                text: "Vendors List",
                link: "/store-reporting/vendors-list",
              },
              {
                id: 86,
                text: "Vendors Payout",
                link: "/store-reporting/vendors-sales-reports",
              },
            ],
          },
          {
            id: 115,
            text: "Lottery Payout Report",
            link: "/store-reporting/lottery-payout-report",
          },
        ];
        const updatedTest = updatedMenuItems.map((item) => {
          if (item.id === 10 && item.dropdownItems) {
            return {
              ...item,
              dropdownItems: [
                ...item.dropdownItems,
                newregistersetting,
                newMenuItemDigit,
              ],
            };
          }

          if (item.id === 4 && item.dropdownItems) {
            return {
              ...item,
              dropdownItems: [
                ...item.dropdownItems,
                ...newUpdateInventoryMerge,
              ],
            };
          }
          if (item.id === 3 && item.dropdownItems) {
            const issuedLoyaltyProgram = {
              id: 1729596404509111,
              text: "Issued Loyalty Program",
              link: "/store-reporting/loyalty-program",
            };
            const newDropdownItems = [...item.dropdownItems];
            newDropdownItems.splice(8, 0, issuedLoyaltyProgram);
            return {
              ...item,
              dropdownItems: [
                ...newDropdownItems,
                ...updatedReportingForSuperAdmin,
              ],
            };
          }
          return item;
        });
        // console.log("test",updatedTest)
        setMenuItemSwitcher(updatedTest);
      }
    }
  }, [currentUrl, isStoreActive]);

  useEffect(() => {
    if (LoginGetDashBoardRecordJson?.login_type === "superadmin") {
      setMenuItemSwitcher((menuItems) => [...menuItems]);
    }
  }, [LoginGetDashBoardRecordJson?.login_type]);

  function getFirstTwoSegmentsPath(pathname) {
    // console.log("getFirstTwoSegmentsPath",pathname)
    const segments = pathname?.split("/").filter(Boolean);

    return "/" + segments?.slice(0, 2).join("/");
  }
  function getFirstTwoSegmentsPathIsStoreAvtive(pathname) {
    // console.log("getFirstTwoSegmentsPath",pathname)
    const segments = pathname.split("/").filter(Boolean);
    return isStoreActive
      ? "/" + segments.slice(0, 1).join("/")
      : "/" + segments.slice(0, 2).join("/");
  }

  // function handleHowerOnIcons(id) {
  //   setHoveredItem(id);
  //   dispatch(setMenuOpen(Boolean(id)));
  // }
  // const [openedByHover, setOpenedByHover] = useState(false);
  function handleHowerOnIcons(id) {
    setHoveredItem(id);
  }
  // console.log("isOpenedByHover", isMenuOpenRedux);
  function handleHowerOnIconsTest() {
    // console.log("leaving");
    if (!isTabletNav) {
      if (!isMenuOpenRedux) {
        // Menu is closed, so we open it on hover
        // console.log("setMenuOpen inside isMenuOpenRedux");
        dispatch(setMenuOpen(true));
        dispatch(setIsOpenedByHover(true)); // Mark that the menu was opened via hover
      } else if (isOpenedByHover) {
        // When mouse leaves and the menu was opened by hover, close the menu
        dispatch(setMenuOpen(false));
        dispatch(setIsDropdownOpen(false)); // Close dropdown as well
        dispatch(setIsOpenedByHover(false)); // Reset hover-based open tracking
      }
    }
  }
  return isMobileNav ? (
    <>
      <div
        onMouseEnter={() => handleHowerOnIconsTest()}
        onMouseLeave={() => handleHowerOnIconsTest()}
        className="sidebar-menu custom-scroll"
        // id="custom-scroll"
        style={{
          width: isMenuOpenRedux ? "16rem" : "4rem",
          paddingTop: "69px",
        }}
      >
        {/* || LoginAllStore?.final_login==1 */}
        {/* Left Side Menu */}
        <div className="flex-1 bg-[#253338] text-[#9E9E9E]">
          {isMenuOpenRedux
            ? (LoginGetDashBoardRecordJson?.final_login == 1
                ? temp["superadmin"]
                : temp[LoginGetDashBoardRecordJson?.data?.login_type]
              ) // admin
                ?.map((item) => (
                  <div
                    key={item.id}
                    className={`text-[#9E9E9E] active:bg-[#414F54] hover:bg-[#414F54] hover:text-[#FFC400] px-0 ${
                      activeItem === item.link ? "active" : ""
                    }`}
                  >
                    {item.dropdownItems ? (
                      <DropdownMenuItem
                        setHoveredItem={setHoveredItem}
                        item={item}
                        isMenuOpenRedux={isMenuOpenRedux}
                        activeItem={activeItem}
                        setActiveItem={setActiveItem}
                        hoveredItem={hoveredItem}
                        isDropdownOpen={isDropdownOpen}
                        setIsDropdownOpen={setIsDropdownOpen}
                        currentDropDownItem={currentDropDownItem}
                        activeDropDownItem={activeDropDownItem}
                        activeNestedItem={activeNestedItem}
                        setActiveNestedItem={setActiveNestedItem}
                        getFirstTwoSegmentsPath={getFirstTwoSegmentsPath}
                        handleHowerOnIcons={handleHowerOnIcons}
                        isOpenedByHover={isOpenedByHover}
                      />
                    ) : (
                      <div
                        onMouseEnter={() => handleHowerOnIcons(item.id)}
                        onMouseLeave={() => handleHowerOnIcons(null)}
                        onClick={() => handleItemClick(item)}
                        style={{ cursor: "pointer" }}
                        className={`flex items-center ${item?.className} ${
                          getFirstTwoSegmentsPathIsStoreAvtive(activeItem) ===
                          item.link.trim()
                            ? "bg-[#414F54] text-[#FFC400]"
                            : ""
                              ? "text-[#FFC400] active:bg-[#414F54] hover:bg-[#414F54] px-0"
                              : ""
                        }`}
                      >
                        {getFirstTwoSegmentsPathIsStoreAvtive(activeItem) ===
                          item.link.trim() || hoveredItem === item.id
                          ? item.activeIcon
                          : item.icon}
                        <Link
                          className={`ml-2 menu-item text-[14px] Admin_std ${
                            activeItem === item.link.trim()
                              ? "bg-[#414F54]"
                              : ""
                          }`}
                          to={item.link}
                        >
                          {item.text}
                        </Link>
                      </div>
                    )}
                  </div>
                ))
            : (LoginGetDashBoardRecordJson?.final_login == 1
                ? temp["superadmin"]
                : temp[LoginGetDashBoardRecordJson?.data?.login_type]
              )?.map((item) => (
                <div
                  key={item.id}
                  className={`mb-1 text-base cursor-pointer ${
                    activeItem === item.link ? "active" : ""
                  }`}
                >
                  {item.dropdownItems ? (
                    <DropdownMenuItem
                      setHoveredItem={setHoveredItem}
                      item={item}
                      isMenuOpenRedux={isMenuOpenRedux}
                      activeItem={activeItem}
                      setActiveItem={setActiveItem}
                      hoveredItem={hoveredItem}
                      isDropdownOpen={isDropdownOpen}
                      setIsDropdownOpen={setIsDropdownOpen}
                      currentDropDownItem={currentDropDownItem}
                      activeDropDownItem={activeDropDownItem}
                      activeNestedItem={activeNestedItem}
                      setActiveNestedItem={setActiveNestedItem}
                      getFirstTwoSegmentsPath={getFirstTwoSegmentsPath}
                      handleHowerOnIcons={handleHowerOnIcons}
                      isOpenedByHover={isOpenedByHover}
                    />
                  ) : (
                    <div
                      className={`flex flex-col items-center ${
                        activeItem === item.link
                          ? "text-[#FFC400] active"
                          : "text-gray-400 hover-text-yellow hover:bg-[#414F54] px-0"
                      } ${item?.className}`}
                      onMouseEnter={() => handleHowerOnIcons(item.id)}
                      onMouseLeave={() => handleHowerOnIcons(null)}
                      onClick={() => {
                        handleItemClick(item);
                      }}
                    >
                      {/* {activeItem === item.id ? item.activeIcon : item.icon} */}
                      {getFirstTwoSegmentsPathIsStoreAvtive(activeItem) ===
                        item.link.trim() || hoveredItem === item.id
                        ? item.activeIcon
                        : item.icon}
                    </div>
                  )}
                </div>
              ))}
        </div>
      </div>
    </>
  ) : isMobileViewActive ? (
    <div
      className="sidebar-menu custom-scroll"
      // id="custom-scroll"
      style={{
        width: isMenuOpenRedux ? "16rem" : "4rem",
        paddingTop: "69px",
      }}
    >
      {/* || LoginAllStore?.final_login==1 */}
      {/* Left Side Menu */}

      <div className="flex-1 bg-[#253338] text-[#9E9E9E]">
        {isMenuOpenRedux
          ? (LoginGetDashBoardRecordJson?.final_login == 1
              ? temp["superadmin"]
              : temp[LoginGetDashBoardRecordJson?.data?.login_type]
            ) // admin
              ?.map((item) => (
                <div
                  key={item.id}
                  className={`text-[#9E9E9E] active:bg-[#414F54] hover:bg-[#414F54] hover:text-[#FFC400] px-0 ${
                    activeItem === item.link ? "active" : ""
                  }`}
                >
                  {item.dropdownItems ? (
                    <DropdownMenuItem
                      setHoveredItem={setHoveredItem}
                      item={item}
                      isMenuOpenRedux={isMenuOpenRedux}
                      activeItem={activeItem}
                      setActiveItem={setActiveItem}
                      hoveredItem={hoveredItem}
                      isDropdownOpen={isDropdownOpen}
                      setIsDropdownOpen={setIsDropdownOpen}
                      currentDropDownItem={currentDropDownItem}
                      activeDropDownItem={activeDropDownItem}
                      activeNestedItem={activeNestedItem}
                      setActiveNestedItem={setActiveNestedItem}
                      getFirstTwoSegmentsPath={getFirstTwoSegmentsPath}
                      isOpenedByHover={isOpenedByHover}
                      handleHowerOnIcons={handleHowerOnIcons}
                    />
                  ) : (
                    <div
                      onMouseEnter={() => setHoveredItem(item.id)}
                      onMouseLeave={() => setHoveredItem(null)}
                      onClick={() => handleItemClick(item)}
                      style={{ cursor: "pointer" }}
                      className={`flex items-center ${item?.className} ${
                        getFirstTwoSegmentsPathIsStoreAvtive(activeItem) ===
                        item.link.trim()
                          ? "bg-[#414F54] text-[#FFC400]"
                          : ""
                            ? "text-[#FFC400] active:bg-[#414F54] hover:bg-[#414F54] px-0"
                            : ""
                      }`}
                    >
                      {getFirstTwoSegmentsPathIsStoreAvtive(activeItem) ===
                        item.link.trim() || hoveredItem === item.id
                        ? item.activeIcon
                        : item.icon}
                      <Link
                        className={`ml-2 menu-item text-[14px] Admin_std ${
                          activeItem === item.link.trim() ? "bg-[#414F54]" : ""
                        }`}
                        to={item.link}
                      >
                        {item.text}
                      </Link>
                    </div>
                  )}
                </div>
              ))
          : (LoginGetDashBoardRecordJson?.final_login == 1
              ? temp["superadmin"]
              : temp[LoginGetDashBoardRecordJson?.data?.login_type]
            )?.map((item) => (
              <div
                key={item.id}
                className={`mb-1 text-base cursor-pointer ${
                  activeItem === item.link ? "active" : ""
                }`}
              >
                {item.dropdownItems ? (
                  <DropdownMenuItem
                    setHoveredItem={setHoveredItem}
                    item={item}
                    isMenuOpenRedux={isMenuOpenRedux}
                    activeItem={activeItem}
                    setActiveItem={setActiveItem}
                    hoveredItem={hoveredItem}
                    isDropdownOpen={isDropdownOpen}
                    setIsDropdownOpen={setIsDropdownOpen}
                    currentDropDownItem={currentDropDownItem}
                    activeDropDownItem={activeDropDownItem}
                    activeNestedItem={activeNestedItem}
                    setActiveNestedItem={setActiveNestedItem}
                    getFirstTwoSegmentsPath={getFirstTwoSegmentsPath}
                    isOpenedByHover={isOpenedByHover}
                    handleHowerOnIcons={handleHowerOnIcons}
                  />
                ) : (
                  <div
                    className={`flex flex-col items-center ${
                      activeItem === item.link
                        ? "text-[#FFC400] active"
                        : "text-gray-400 hover-text-yellow hover:bg-[#414F54] px-0"
                    } ${item?.className}`}
                    onMouseEnter={() => setHoveredItem(item.id)}
                    onMouseLeave={() => setHoveredItem(null)}
                    onClick={() => {
                      handleItemClick(item);
                    }}
                  >
                    {/* {activeItem === item.id ? item.activeIcon : item.icon} */}
                    {getFirstTwoSegmentsPathIsStoreAvtive(activeItem) ===
                      item.link.trim() || hoveredItem === item.id
                      ? item.activeIcon
                      : item.icon}
                  </div>
                )}
              </div>
            ))}
      </div>
    </div>
  ) : (
    ""
  );
};

const DropdownMenuItem = ({
  item,
  isMenuOpenRedux,
  activeItem,
  setActiveItem,
  setHoveredItem,
  hoveredItem,
  isDropdownOpen,
  setIsDropdownOpen,
  activeDropDownItem,
  currentDropDownItem,
  activeNestedItem,
  setActiveNestedItem,
  getFirstTwoSegmentsPath,
  handleHowerOnIcons,
  isOpenedByHover,
}) => {
  const dispatch = useDispatch();

  const [dropDownItem, setDropDownItem] = useState(null);

  const isTabletNav = useMediaQuery("(max-width:1024px)");
  useEffect(() => {
    const foundItem = item?.dropdownItems?.find(
      (item) =>
        getFirstTwoSegmentsPath(item?.link) ===
        getFirstTwoSegmentsPath(activeItem)
    );

    if (foundItem) {
      setDropDownItem(foundItem?.link);
      if (isTabletNav) {
        activeDropDownItem(item?.id);
      }
      if (isMenuOpenRedux) {
        activeDropDownItem(item?.id);
      }
    }

    const NesedFoundItem = item?.dropdownItems?.find((nestedItem) => {
      if (nestedItem?.dropDownItems) {
        return (
          nestedItem?.dropDownItems?.find(
            (dropDownItem) => dropDownItem.link === activeItem
          ) && nestedItem
        );
      }
    });
    if (NesedFoundItem) {
      setActiveNestedItem(NesedFoundItem?.id);
      setDropDownItem(activeItem);
      isMenuOpenRedux && activeDropDownItem(item.id);
    }
    // item.id === currentDropDownItem && dispatch(setIsDropdownOpen(true));
    if (isTabletNav) {
      dispatch(setIsDropdownOpen(!isTabletNav));
    }
    if (isMenuOpenRedux) {
      dispatch(setIsDropdownOpen(isMenuOpenRedux));
    }
  }, [isTabletNav, dropDownItem, isTabletNav, activeItem, isMenuOpenRedux]);

  const handleToggleDropdownItems = (link, e) => {
    if (isTabletNav) {
      dispatch(setIsDropdownOpen(false));
      dispatch(setMenuOpen(false));
      dispatch(setIsMobileViewActive(false));
      setHoveredItem(null);
    }
    setActiveItem(link);
    setDropDownItem(link);
    setActiveNestedItem(null);
  };

  const handleToggleSideBar = () => {
    dispatch(setMenuOpen(!isMenuOpenRedux));
    dispatch(setIsDropdownOpen(true));
  };

  const HandleDropdownClick = (id) => {
    activeDropDownItem((prevId) => (prevId === id ? null : id));
  };
  const HandleDropdownIconClick = (id) => {
    activeDropDownItem(id);
  };
  return (
    <>
      <div
        className={`relative ${item?.className}`}
        style={
          isMenuOpenRedux
            ? { width: "16rem" }
            : { width: "6rem", marginLeft: "10px" }
        }
        onMouseEnter={() => handleHowerOnIcons(item.id)}
        onMouseLeave={() => handleHowerOnIcons(null)}
        onClick={(e) => {
          HandleDropdownClick(item.id);
          dispatch(setIsDropdownOpen(true));
        }}
      >
        <div className={`flex`}>
          {isMenuOpenRedux ? (
            <div
              className={`w-full flex items-center cursor-pointer ${
                getFirstTwoSegmentsPath(activeItem) ===
                getFirstTwoSegmentsPath(dropDownItem)
                  ? "bg-[#414F54]"
                  : ""
              }`}
            >
              {getFirstTwoSegmentsPath(activeItem) ===
                getFirstTwoSegmentsPath(dropDownItem) || hoveredItem === item.id
                ? item.activeIcon
                : item.icon}
              <p
                className={`ml-2 menu-item DropDown-memu text-[14px] flex-auto Admin_std ${
                  getFirstTwoSegmentsPath(activeItem) ===
                  getFirstTwoSegmentsPath(dropDownItem)
                    ? "activeTab"
                    : ""
                }`}
              >
                {item.text}
              </p>
              {currentDropDownItem === item.id ? (
                <FaChevronUp
                  className={`quickarrow_icon ml-4 me-5 text-${
                    (getFirstTwoSegmentsPath(activeItem) ===
                      getFirstTwoSegmentsPath(dropDownItem) ||
                      hoveredItem === item.id) &&
                    "[#FFC400]"
                  }`}
                />
              ) : (
                <FaChevronDown
                  className={`quickarrow_icon ml-4 me-5 text-${
                    (getFirstTwoSegmentsPath(activeItem) ===
                      getFirstTwoSegmentsPath(dropDownItem) ||
                      hoveredItem === item.id) &&
                    "[#FFC400]"
                  }`}
                />
              )}
            </div>
          ) : (
            <>
              <div
                onClick={(e) => {
                  handleToggleSideBar();
                  HandleDropdownIconClick(item.id);
                  e.stopPropagation();
                }}
              >
                {getFirstTwoSegmentsPath(activeItem) ===
                  getFirstTwoSegmentsPath(dropDownItem) ||
                hoveredItem === item.id
                  ? item.activeIcon
                  : item.icon}{" "}
              </div>
            </>
          )}
        </div>
      </div>

      {isDropdownOpen && currentDropDownItem === item.id && (
        <div
          onMouseEnter={(e) => {
            setHoveredItem(item.id);
            // e.stopPropagation();
          }}
          onMouseLeave={(e) => {
            setHoveredItem(null);
            // e.stopPropagation();
          }}
          className="mt-0 bg-[#334247]  shadow w-full text-center z-10"
        >
          {item?.dropdownItems?.map((nestedDropdownItem) => (
            <React.Fragment key={nestedDropdownItem.id}>
              {nestedDropdownItem?.dropDownItems ? (
                <>
                  <NestedDropdownMenu
                    item={nestedDropdownItem}
                    isMenuOpenRedux={isMenuOpenRedux}
                    activeItem={activeItem}
                    dropDownItem={dropDownItem}
                    hoveredItem={hoveredItem}
                    handleToggleDropdownItems={handleToggleDropdownItems}
                    setHoveredItem={setHoveredItem}
                    activeNestedItem={activeNestedItem}
                    setActiveNestedItem={setActiveNestedItem}
                    setDropDownItem={setDropDownItem}
                    activeDropDownItem={activeDropDownItem}
                    isTabletNav={isTabletNav}
                  />
                </>
              ) : (
                <Link
                  // key={nestedDropdownItem.id}
                  to={nestedDropdownItem.link}
                  className={`flex text-center submenu-item text-gray-400 p-4 text-[14px] ${
                    getFirstTwoSegmentsPath(activeItem) ===
                    getFirstTwoSegmentsPath(nestedDropdownItem.link)
                      ? "active"
                      : ""
                  } ${nestedDropdownItem?.className}`}
                  onClick={(e) => {
                    handleToggleDropdownItems(nestedDropdownItem.link);
                    e.stopPropagation();
                  }}
                >
                  {nestedDropdownItem.text}
                </Link>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};

// Define menu items with icons and text
// {LoginAllStore?.data?.login_type!==("admin" &&"manager"&& "merchant")

const menuItems = [
  {
    id: 1,
    icon: (
      <img
        src={DashIcon}
        alt="Dashboard"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img src={DashboardIcon} alt="Dashboard" className="h-6 w-10 mt-4 mb-4" />
    ),
    text: "Dashboard",
    link: "/",
    className: "Dashboard-for-android",
  },
  {
    id: 2,
    icon: (
      <img
        src={ShoppingCartIcon}
        alt="Order"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img src={OrderYellow} alt="order" className="h-6 w-10 mt-4 mb-4" />
    ),
    text: "Orders",
    link: "/order",
    className: "Orders-for-android",
  },
  {
    id: 3,
    icon: (
      <img
        src={ReportIcon}
        alt="store"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img
        src={ResportIcons}
        alt="active report"
        className="h-6 w-10 mt-4 mb-4"
      />
    ),
    text: "Reporting",
    link: "/reporting",
    className: "Reporting-for-android",
    dropdownItems: [
      // {
      //   id: 1,
      //   text: "Sales Reports",
      //   dropDownItems: [
      //     {
      //       id: 61,
      //       text: "Sales Summary",
      //       link: "/store-reporting/sales-report",
      //     },
      //     {
      //       id: 108,
      //       text: "Sales Report",
      //       link: "/store-reporting/new-sales-report",
      //     },
      //     {
      //       id: 77,
      //       text: "Item Sales",
      //       link: "/store-reporting/item-sales",
      //     },
      //     {
      //       id: 98,
      //       text: "Category Sales Summary Report",
      //       link: "/store-reporting/category-sales-summery-report",
      //     },
      //     {
      //       id: 62,
      //       text: "Daily Total Report",
      //       link: "/store-reporting/daily-total-report",
      //     },
      //     {
      //       id: 63,
      //       text: "Detailed Category Sale",
      //       link: "/store-reporting/Details-category",
      //     },
      //     {
      //       id: 64,
      //       text: "Detailed Sales Person Report",
      //       link: "/store-reporting/report-sales-person",
      //     },

      //     {
      //       id: 96,
      //       text: "Drop Cash Report",
      //       link: "/store-reporting/drop-cash-report",
      //     },
      //     {
      //       id: 102,
      //       text: "Gift Card Report",
      //       link: "/store-reporting/gift-card-report",
      //     },

      //     {
      //       id: 79,
      //       text: "Order Type",
      //       link: "/store-reporting/order-type",
      //     },
      //     {
      //       id: 97,
      //       text: "Pay In Report",
      //       link: "/store-reporting/pay-in-report",
      //     },
      //     {
      //       id: 78,
      //       text: "Payment Method Details",
      //       link: "/store-reporting/payment-method-details",
      //     },
      //     {
      //       id: 90,
      //       text: "Product Profitability Report",
      //       link: "/store-reporting/item-sale-profit-report",
      //     },
      //     // {
      //     //   id: 99,
      //     //   text: "Sales by Hour Report",
      //     //   link: "/store-reporting/sales-by-hour-report",
      //     // },

      //     {
      //       id: 69,
      //       text: "Top Sellers",
      //       link: "/store-reporting/overall-top",
      //     },

      //     // {
      //     //   id: 78,
      //     //   text: "Discount Per Sales Report",
      //     //   link: "/store-reporting/discount-per-sales-report",
      //     // },

      //     // {
      //     //   id: 105,
      //     //   text: "New Item Sales Report",
      //     //   link: "/store-reporting/new-item-sales-report",
      //     // },
      //   ],
      // },
      {
        id: 80,
        text: "Sales Reports",
        link: "/store-reporting/new-sale-report/sales-summary",
        className: "Sales-Reports-for-android",
      },
      // {
      //   id: 2,
      //   text: "Inventory Reports",
      //   dropDownItems: [
      //     {
      //       id: 81,
      //       text: "Current Inventory Value11",
      //       link: "/store-reporting/current-inventory-value",
      //     },
      //     {
      //       id: 73,
      //       text: "New Item Created Between",
      //       link: "/store-reporting/item-create-between",
      //     },
      //     {
      //       id: 74,
      //       text: "Reorder Inventory",
      //       link: "/store-reporting/recorder-inventory",
      //     },
      //     {
      //       id: 68,
      //       text: "Instant PO Activity Report",
      //       link: "/store-reporting/instant-activity",
      //     },
      //     {
      //       id: 65,
      //       text: "Check ID verification",
      //       link: "/store-reporting/id-verification",
      //     },
      //     {
      //       id: 93,
      //       text: "Inventory List",
      //       link: "/store-reporting/inventory-list",
      //     },
      //     {
      //       id: 94,
      //       text: "Profit Margin Per Item Listing",
      //       link: "/store-reporting/profit-margin-report",
      //     },
      //   ],
      // },
      {
        id: 2,
        text: "Inventory Reports",
        // link: "/store-reporting/inventory-report/recorder-inventory",
        link: "/store-reporting/inventory-report/recorder-inventory",
        className: "Inventory-Reports-for-android",
      },
      {
        id: 1729761292981,
        text: "Customer Reports",
        link: "/store-reporting/customer-reports/customers",
        className: "Customer-Reports-for-android",
      },
      {
        id: 1729851529495,
        text: "Employee Reports",
        link: "/store-reporting/employee-reports/employee-list",
        className: "Employee-Reports-for-android",
      },
      {
        id: 116,
        text: "Payment Report",
        link: "/store-reporting/payment-report",
        className: "Payment-Report-for-android",
      },
      {
        id: 1726123837135,
        text: "Register Activity",
        link: "/store-reporting/register-activity/register-closures",
        className: "Register-Activity-for-android",
      },
      {
        id: 1728556297041,
        text: "Refund Report",
        link: "/store-reporting/refund-report-main/order-refund-report",
        className: "Refund-Report-for-android",
      },
      {
        id: 1728561041725,
        text: "Loyalty Report",
        link: "/store-reporting/detailed-loyalty-points-report",
        className: "Loyalty-Report-for-android",
      },

      // {
      //   id: 4,
      //   text: "Refunds",
      //   dropDownItems: [
      //     {
      //       id: 84,
      //       text: "Item Refund Report",
      //       link: "/store-settings/refund-report",
      //     },
      //     {
      //       id: 82,
      //       text: "Order Refund Report",
      //       link: "/store-settings/order-refund-report",
      //     },
      //     // {
      //     //   id: 85,
      //     //   text: "Refunded Order ID's",
      //     //   link: "/store-settings/#",
      //     // },
      //   ],
      // },
      {
        id: 1729596404509,
        text: "Store Credit Report",
        link: "/store-reporting/store-credit-report",
        className: "Store-Credit-Report-for-android",
      },

      {
        id: 172959640451101,
        text: "Gift Card Reports",
        link: "/store-reporting/gift-card-report",
        className: "Gift-Card-Reports-for-android",
      },

      {
        id: 1732688013753,
        text: "Tax Report",
        link: "/store-reporting/new-taxes-report",
        className: "Tax-Report-for-android",
      },

      // {
      //   id: 1728561073906,
      //   text: "Employee Hours",
      //   link: "##",
      // },

      // {
      //   id: 1723011930198,
      //   text: "Employee Reports",
      //   dropDownItems: [
      //     {
      //       id: 102,
      //       text: "Employee Sales Per Category",
      //       link: "/store-reporting/employee-sales-per-category-report",
      //     },
      //   ],
      // },
      // { id: 80, text: "Taxes ", link: "/store-reporting/taxes-report" },

      // {
      //   id: 66,
      //   text: "Vendor Sales Report",
      //   link: "/store-reporting/vendors-sales-reports",
      // },
      // {
      //   id: 90,
      //   text: "Item Sales Profit Report",
      //   link: "/store-reporting/item-sale-profit-report",
      // },
      // {
      //   id: 67,
      //   text: "Credit Debit Sales Report",
      //   link: "/store-reporting/credit-debit-sales",
      // },

      // { id: 70, text: "Flash Report", link: "/store-reporting/flash-resigter" },

      // { id: 76, text: "Shift Summary", link: "/store-reporting/shift-summary" },

      // { id: 77, text: " Item Sales ", link: "/store-settings/item-sales" },
      // {
      //   id: 78,
      //   text: " Payment Method Details",
      //   link: "/store-settings/payment-method-detail-report",
      // },

      // { id: 77, text: " Item Sales ", link: "/store-reporting/item-sales" },
      // { id: 87, text: "Tip Report", link: "/store-reporting/tip-report" },
      // { id: 88, text: "Coupon Report", link: "/store-reporting/coupon-report" },
      // { id: 1726033807930, text: "Shift Summary", link: "/store-reporting/shift-summary" },

      // {
      //   id: 89,
      //   text: "Discount Per Sales Person",
      //   link: "/store-reporting/discount-per-sales-report",
      // },
      // {
      //   id: 6,
      //   text: "New Reports",
      //   dropDownItems: [
      //     {
      //       id: 104,
      //       text: "Profit Summary Report",
      //       link: "/store-reporting/profit-summary-report",
      //     },
      //   ],
      // },
    ],
  },
  {
    id: 4,
    icon: (
      <img
        src={Inventory_Icon}
        alt="Products"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img
        src={Inventory_Active_Icon}
        alt="Category"
        className="h-6 w-10 mt-4 mb-4 "
      />
    ),
    text: "Inventory",
    className: "Inventory-for-android",
    dropdownItems: [
      {
        id: 1,
        icon: (
          <img
            src={CategoryIconActive}
            alt="Category"
            className="h-6 w-10 mt-4 mb-4 hoverable-image"
          />
        ),
        activeIcon: (
          <img src={CatIcon} alt="Category" className="h-6 w-10 mt-4 mb-4 " />
        ),
        text: "Category",
        link: "/inventory/category",
        className: "Category-for-android",
      },
      {
        id: 2,
        icon: (
          <img
            src={ProductIcon}
            alt="Products"
            className="h-6 w-10 mt-4 mb-4 hoverable-image"
          />
        ),
        activeIcon: (
          <img src={ProdIcon} alt="Products" className="h-6 w-10 mt-4 mb-4 " />
        ),
        text: "Products",
        link: "/inventory/products",
        className: "Products-for-android",
      },
      {
        id: 3,
        icon: (
          <img
            src={AttributesIcon}
            alt="Attributes"
            className="h-6 w-10 mt-4 mb-4 hoverable-image"
          />
        ),
        activeIcon: (
          <img src={AtriIcon} alt="atributes" className="h-6 w-10 mt-4 mb-4" />
        ),
        text: "Attributes",
        link: "/attributes",
        className: "Attributes-for-android",
      },
      {
        id: 95,
        icon: (
          <img
            src={AttributesIcon}
            alt="brand"
            className="h-6 w-10 mt-4 mb-4 hoverable-image"
          />
        ),
        activeIcon: (
          <img src={AtriIcon} alt="atributes" className="h-6 w-10 mt-4 mb-4" />
        ),
        text: "Brand",
        link: "/brands",
        className: "Brand-for-android",
      },
      {
        id: 3,
        icon: (
          <img
            src={AttributesIcon}
            alt="Tag"
            className="h-6 w-10 mt-4 mb-4 hoverable-image"
          />
        ),
        activeIcon: (
          <img src={AtriIcon} alt="atributes" className="h-6 w-10 mt-4 mb-4" />
        ),
        text: "Tag",
        link: "/tags",
        className: "Tag-for-android",
      },
      // {
      //   id: 1726725862759,
      //   icon: (
      //     <img
      //       src={AttributesIcon}
      //       alt="Lottery"
      //       className="h-6 w-10 mt-4 mb-4 hoverable-image"
      //     />
      //   ),
      //   activeIcon: (
      //     <img src={AtriIcon} alt="Lottery" className="h-6 w-10 mt-4 mb-4" />
      //   ),
      //   text: "Lottery",
      //   link: "/inventory/lottery",
      // },
      // {
      //   id: 1728378243020,
      //   icon: (
      //     <img
      //       src={MixMatchIcon}
      //       alt="mix-match-icon"
      //       className="h-6 w-10 mt-4 mb-4"
      //     />
      //   ),
      //   activeIcon: (
      //     <img
      //       src={MixMatchIcon1}
      //       alt="mix-match-icon-1"
      //       className="h-6 w-10 mt-4 mb-4 "
      //     />
      //   ),
      //   text: "Mix N' Match Pricing",
      //   link: "/inventory/mix-and-match",
      // },
    ],
  },
  {
    id: 5,
    icon: (
      <img
        src={PurchaseIcon}
        alt="Purchase Data"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img src={PurIcon} alt="Purchase" className="h-6 w-10 mt-4 mb-4" />
    ),
    text: "Purchase Orders",
    link: "/purchase-data",
    className: "Purchase-Orders-for-android",
  },
  {
    id: 12,
    icon: (
      <img
        src={Stock_take_Icon}
        alt="Purchase Data"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img
        src={Stock_take_Active_Icon}
        alt="Purchase"
        className="h-6 w-10 mt-4 mb-4"
      />
    ),
    text: "Stocktake",
    link: "/stocktake",
    className: "Stocktake-for-android",
  },
  {
    id: 6,
    icon: (
      <img src={Vendor_Icon} alt="Vendors" className="h-6 w-10 mt-4 mb-4" />
    ),
    activeIcon: (
      <img
        src={Vendor_Active_icon}
        alt="Vendors"
        className="h-6 w-10 mt-4 mb-4 "
      />
    ),
    text: "Vendors",
    link: "/vendors",
    className: "Vendors-for-android",
  },
  {
    id: 7,
    icon: (
      <img
        src={Employees_Icon}
        alt="Employees"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img
        src={Employees_Active_Icon}
        alt="Import"
        className="h-6 w-10 mt-4 mb-4 "
      />
    ),
    text: "Employees",
    className: "Employees-for-android",
    dropdownItems: [
      // {
      //   id: 1,
      //   text: "Employees",
      //   link: "/#",
      // },
      {
        id: 66,
        icon: (
          <img
            src={CouponIcon}
            alt="Add Employee"
            className="h-6 w-10 mt-4 mb-4"
          />
        ),
        activeIcon: (
          <img src={CouIcon} alt="option" className="h-6 w-10 mt-4 mb-4 " />
        ),
        text: "Manage Employees",
        link: "/employee/addemployee",
        className: "Manage-Employees-for-android",
      },
      {
        id: 2,
        icon: (
          <img
            src={timesheetblackIcon}
            alt="Timesheet"
            className="h-6 w-10 mt-4 mb-4 hoverable-image"
          />
        ),
        activeIcon: (
          <img
            src={TimesheetsIcon}
            alt="Timesheet"
            className="h-6 w-10 mt-4 mb-4 "
          />
        ),
        text: "Timesheet",
        link: "/timesheet",
        className: "Timesheet-for-android",
      },
    ],
  },
  {
    id: 8,
    icon: <img src={CouponIcon} alt="Coupons" className="h-6 w-10 mt-4 mb-4" />,
    activeIcon: (
      <img src={CouIcon} alt="Coupons" className="h-6 w-10 mt-4 mb-4 " />
    ),
    text: "Coupons",
    link: "/coupons",
    className: "Coupons-for-android",
  },

  {
    id: 9,
    icon: (
      <img
        src={MixMatchIcon}
        alt="mix-match-icon"
        className="h-6 w-10 mt-4 mb-4"
      />
    ),
    activeIcon: (
      <img
        src={MixMatchIcon1}
        alt="mix-match-icon-1"
        className="h-6 w-10 mt-4 mb-4 "
      />
    ),
    text: "Mix N' Match Pricing",
    link: "/mix-and-match",
    className:
      "Mix-And-Match-Pricing-for-android Mix-N'-Match-Pricing-for-android",
  },
  {
    id: 65,
    icon: <img src={Tax_Icon} alt="Coupons" className="h-6 w-10 mt-4 mb-4" />,
    activeIcon: (
      <img
        src={Tax_Active_Icon}
        alt="Coupons"
        className="h-6 w-10 mt-4 mb-4 "
      />
    ),
    text: "Taxes",
    link: "/taxes",
    className: "Taxes-for-android",
  },
  {
    id: 10,
    icon: (
      <img
        src={StoreSettingIcon}
        alt="store"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img
        src={SettingIcon}
        alt="active store"
        className="h-6 w-10 mt-4 mb-4"
      />
    ),
    text: "Store Settings",
    link: "/store-settings/info",
    className: "Store-Settings-for-android",
    dropdownItems: [
      // {
      //   id: 6,
      //   text: "Store",
      //   className: "flex items-center gap-2",
      //   dropDownItems: [
      //     // { id: 1, text: "Profile", link: "#" },
      //     { id: 61, text: "Info", link: "/store-settings/info" },
      //     { id: 62, text: "Setup", link: "/store-settings/setup" },
      //     {
      //       id: 63,
      //       icon: (
      //         <img
      //           src={CouponIcon}
      //           alt="option"
      //           className="h-6 w-10 mt-4 mb-4"
      //         />
      //       ),
      //       activeIcon: (
      //         <img src={CouIcon} alt="option" className="h-6 w-10 mt-4 mb-4 " />
      //       ),
      //       text: "Option",
      //       link: "/store-settings/options",
      //     },
      //   ],
      // },
      {
        id: 1728376800275,
        text: "Store Info",
        link: "/store-settings/info",
        className: "Store-Info-for-android",
      },
      {
        id: 1728376814838,
        text: "Store Setup",
        link: "/store-settings/setup",
        className: "Store-Setup-for-android",
      },
      {
        id: 1728376832601,
        icon: (
          <img src={CouponIcon} alt="option" className="h-6 w-10 mt-4 mb-4" />
        ),
        activeIcon: (
          <img src={CouIcon} alt="option" className="h-6 w-10 mt-4 mb-4 " />
        ),
        text: "Store Option",
        link: "/store-settings/options",
        className: "Store-Option-for-android",
      },
      {
        id: 64,
        icon: (
          <img src={CouponIcon} alt=" Alerts " className="h-6 w-10 mt-4 mb-4" />
        ),
        activeIcon: (
          <img src={CouIcon} alt=" Alerts " className="h-6 w-10 mt-4 mb-4 " />
        ),
        text: "Alerts",
        link: "/store-settings/Alters",
        className: "Alerts-for-android",
      },
      // { id: 65, text: "Taxes", link: "/store-settings/taxes" },

      // {
      //   id: 66,
      //   icon: (
      //     <img
      //       src={CouponIcon}
      //       alt="Add Employee"
      //       className="h-6 w-10 mt-4 mb-4"
      //     />
      //   ),
      //   activeIcon: (
      //     <img src={CouIcon} alt="option" className="h-6 w-10 mt-4 mb-4 " />
      //   ),
      //   text: "Manage Employees",
      //   link: "/store-settings/addemployee",
      // },
      // { id: 67, text: "Receipt", link: "/store-settings/receipt" },
      {
        id: 68,
        text: "Inventory Settings",
        link: "/store-settings/inventory",
        className: "Inventory-Settings-for-android",
      },
      // { id: 69, text: "Register Settings", link: "/store-settings/register" },
      // { id: 70, text: "Quick Add", link: "/store-settings/quick-add" },
      {
        id: 71,
        text: "Reporting Time",
        link: "/store-settings/system-access",
        className: "Reporting-Time-for-android",
      },
      // {
      //   id: 107,
      //   text: "Loyalty Program",
      //   link: "/store-settings/loyalty-program",
      // },
    ],
  },

  // {
  //   id: 10,
  //   icon: (
  //     <img
  //       src={dataIcon}
  //       alt="Import Data"
  //       className="h-6 w-10 mt-4 mb-4 hoverable-image"
  //     />
  //   ),
  //   activeIcon: (
  //     <img src={DataIconActive} alt="Import" className="h-6 w-10 mt-4 mb-4 " />
  //   ),
  //   text: "Import/Export",
  //   dropdownItems: [
  //     {
  //       id: 1,
  //       icon: (
  //         <img
  //           src={dataIcon}
  //           alt="Import Data"
  //           className="h-6 w-10 mt-4 mb-4 hoverable-image"
  //         />
  //       ),
  //       activeIcon: (
  //         <img
  //           src={DataIconActive}
  //           alt="Import"
  //           className="h-6 w-10 mt-4 mb-4 "
  //         />
  //       ),
  //       text: "Import Inventory",
  //       link: "#",
  //     },
  //     {
  //       id: 2,
  //       icon: (
  //         <img
  //           src={dataIcon}
  //           alt="Export Sold Items"
  //           className="h-6 w-10 mt-4 mb-4 hoverable-image"
  //         />
  //       ),
  //       activeIcon: (
  //         <img
  //           src={DataIconActive}
  //           alt="Import"
  //           className="h-6 w-10 mt-4 mb-4 "
  //         />
  //       ),
  //       text: "Export Sold Items",
  //       link: "/import-data",
  //     },
  //     // { id: 83, text: "Tip Report", link: "/store-reporting/tip-report" },
  //     // { id: 84, text: "Coupon Report", link: "/store-reporting/coupon-report" },
  //   ],
  // },
  // {
  //   id: 12,
  //   icon: (
  //     <img
  //       src={dataIcon}
  //       alt="Import Data"
  //       className="h-6 w-10 mt-4 mb-4 hoverable-image"
  //     />
  //   ),
  //   activeIcon: (
  //     <img src={DataIconActive} alt="Import" className="h-6 w-10 mt-4 mb-4 " />
  //   ),
  //   text: "Import Data",
  //   link: "/import-data",
  // },

  // {
  //   id: 91,
  //   icon: (
  //     <img
  //       src={dataIcon}
  //       alt="Export Sold Items"
  //       className="h-6 w-10 mt-4 mb-4 hoverable-image"
  //     />
  //   ),
  //   activeIcon: (
  //     <img src={DataIconActive} alt="Import" className="h-6 w-10 mt-4 mb-4 " />
  //   ),
  //   text: "Import Data",
  //   link: "/import-data",
  // },
  {
    id: 11,
    icon: (
      <img
        src={Loyalty}
        alt="Loyalty Porogram"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img src={LoyaltIcon} alt="Import" className="h-6 w-10 mt-4 mb-4 " />
    ),
    text: "Loyalty Program",
    // link: "/store-settings/loyalty-program",
    link: "/loyalty-program",
    className: "Loyalty-Program-for-android",
  },
];

const SuperAdminMenuItems = [
  // {
  //   id: 1,
  //   icon: (
  //     <img
  //       src={LabelIcon}
  //       alt="labal"
  //       className="h-6 w-10 mt-4 mb-4 hoverable-image"
  //     />
  //   ),
  //   activeIcon: (
  //     <img src={LabelActive} alt="Label" className="h-6 w-10 mt-4 mb-4" />
  //   ),
  //   text: "Label",
  //   link: "/users/view/unapprove/label",
  // },
  {
    id: 2,
    icon: (
      <img
        src={UsersICcon}
        alt="store"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img src={UserActive} alt="active store" className="h-6 w-10 mt-4 mb-4" />
    ),
    text: "Users",
    link: "/users/view/unapprove/users/add",
    className: "Users-for-android",
    dropdownItems: [
      {
        id: 21,
        text: "Add",
        link: "/users/addMerchant",
        className: "Add-for-android",
      },
      {
        id: 22,
        text: "Verified Merchant",
        link: "/users/approve",
        className: "Verified-Merchant-for-android",
      },
      {
        id: 23,
        icon: (
          <img src={CouponIcon} alt="option" className="h-6 w-10 mt-4 mb-4" />
        ),
        activeIcon: (
          <img src={CouIcon} alt="option" className="h-6 w-10 mt-4 mb-4 " />
        ),
        text: "Unverified Merchant",
        link: "/users/unapprove",
        className: "Unverified-Merchant-for-android",
      },

      {
        id: 26,
        text: "Customer",
        link: "/users/customer",
        className: "Customer-for-android",
      },
      {
        id: 27,
        text: "Admin",
        link: "/users/admin",
        className: "Admin-for-android",
      },
      {
        id: 28,
        text: "Manager",
        link: "/users/manager_view",
        className: "Manager-for-android",
      },
    ],
  },

  // {
  //   id: 3,
  //   icon: (
  //     <img
  //       src={NewsletterIcon}
  //       alt="labal"
  //       className="h-6 w-10 mt-4 mb-4 hoverable-image"
  //     />
  //   ),
  //   activeIcon: (
  //     <img
  //       src={NewsletterActive}
  //       alt="Newsletter"
  //       className="h-6 w-10 mt-4 mb-4"
  //     />
  //   ),
  //   text: "Newsletter",
  //   link: "/users/view/unapprove/newsletter",
  // },
  {
    id: 4,
    icon: (
      <img
        src={StoreIcon}
        alt="Store Order"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img
        src={StoreActive}
        alt="Store Order "
        className="h-6 w-10 mt-4 mb-4"
      />
    ),
    text: "Store Order",
    link: "/unapprove/store-order",
    className: "Store-Order-for-android",
  },
  {
    id: 1722490694702,
    icon: (
      <img
        src={StoreIcon}
        alt="Store Order"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img
        src={StoreActive}
        alt="Refund Request"
        className="h-6 w-10 mt-4 mb-4"
      />
    ),
    text: "Refund Request",
    link: "/unapprove/refund-request",
    className: "Refund-Request-for-android",
  },
  {
    id: 5,
    icon: (
      <img
        src={OrderIcon}
        alt="labal"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img src={OrderActive} alt="Order Count" className="h-6 w-10 mt-4 mb-4" />
    ),
    text: "Order Count",
    // link: "/users/view/unapprove/order-count",
    link: "/unapprove/order-count",
    className: "Order-Count-for-android",
  },

  {
    id: 6,
    icon: (
      <img
        src={DefaultIcon}
        alt="labal"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img
        src={DefaultActive}
        alt="menu-defaults"
        className="h-6 w-10 mt-4 mb-4"
      />
    ),
    text: "Defaults",
    // link: "/users/view/unapprove/menu/defaults",
    link: "/unapprove/defaults",
    className: "Defaults-for-android",
  },

  // {
  //   id: 7,
  //   icon: (
  //     <img
  //       src={ApkIcon}
  //       alt="release_apk"
  //       className="h-6 w-10 mt-4 mb-4 hoverable-image"
  //     />
  //   ),
  //   activeIcon: (
  //     <img src={ApkActive} alt="release_apk" className="h-6 w-10 mt-4 mb-4" />
  //   ),
  //   text: "Release APK   ",
  //   link: "/users/view/unapprove/release_apk",
  // },
  {
    id: 8,
    icon: (
      <img
        src={DuplicatesIcon}
        alt="labal"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img
        src={DuplicatesActive}
        alt="menu-defaults"
        className="h-6 w-10 mt-4 mb-4"
      />
    ),
    text: "Inventory Duplicate",
    link: "/unapprove/inverntory-duplicate",
    className: "Inventory-Duplicate-for-android",
  },

  {
    id: 9,
    icon: (
      <img
        src={DuplicatesIcon}
        alt="labal"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img
        src={DuplicatesActive}
        alt="menu-defaults"
        className="h-6 w-10 mt-4 mb-4"
      />
    ),
    text: "Category Duplicate",
    link: "/unapprove/category-duplicate",
    className: "Category-Duplicate-for-android",
  },

  {
    id: 10,
    icon: (
      <img
        src={DuplicatesIcon}
        alt="duplicates"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img
        src={DuplicatesActive}
        alt="menu-defaults"
        className="h-6 w-10 mt-4 mb-4"
      />
    ),
    text: "Product Duplicate",
    link: "/unapprove/product-duplicate",
    className: "Product-Duplicate-for-android",
  },

  {
    id: 11,
    icon: (
      <img
        src={PermissionIcon}
        alt="labal"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img
        src={PermissionActive}
        alt="menu-defaults"
        className="h-6 w-10 mt-4 mb-4"
      />
    ),
    text: "Permission",
    link: "/unapprove/create_permission",
    className: "Permission-for-android",
    dropdownItems: [
      {
        id: 21,
        text: "Main Permission",
        link: "/unapprove/create_main_permission",
        className: "Main-Permission-for-android",
      },
      {
        id: 22,
        text: "Sub Permission",
        link: "/unapprove/create_permission",
        className: "Sub-Permission-for-android",
      },
    ],
  },

  {
    id: 12,
    icon: (
      <img
        src={InverntoryIcon}
        alt="labal"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img
        src={InvertoryActive}
        alt="menu-defaults"
        className="h-6 w-10 mt-4 mb-4"
      />
    ),
    text: "Inventory Export",
    link: "/unapprove/invertory-export",
    className: "Inventory-Export-for-android",
  },

  {
    id: 14,
    icon: (
      <img
        src={OrderRetrieve}
        alt="labal"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img
        src={OrderRetrievey}
        alt="order-retrieve"
        className="h-6 w-10 mt-4 mb-4"
      />
    ),
    text: "Order Retrieve",
    link: "/unapprove/order-retrieve",
    className: "Order-Retrieve-for-android",
  },

  {
    id: 13,
    icon: (
      <img
        src={Support1}
        alt="labal"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img src={Support} alt="menu-defaults" className="h-6 w-10 mt-4 mb-4" />
    ),
    text: "Support Details",
    link: "/unapprove/support-details",
    className: "Support-Details-for-android",
  },

  // {
  //   id: 13,
  //   icon: (
  //     <img
  //       src={MerchantIcon}
  //       alt="labal"
  //       className="h-6 w-10 mt-4 mb-4 hoverable-image"
  //     />
  //   ),
  //   activeIcon: (
  //     <img
  //       src={MerchantActive}
  //       alt="menu-defaults"
  //       className="h-6 w-10 mt-4 mb-4"
  //     />
  //   ),
  //   text: "Merchant Details ",
  //   link: "/users/view/unapprove/merchant-details",
  // },
  // {
  //   id: 12,
  //   icon: (
  //     <img
  //       src={MerchantIcon}
  //       alt="labal"
  //       className="h-6 w-10 mt-4 mb-4 hoverable-image"
  //     />
  //   ),
  //   activeIcon: (
  //     <img
  //       src={MerchantActive}
  //       alt="menu-defaults"
  //       className="h-6 w-10 mt-4 mb-4"
  //     />
  //   ),
  //   text: "Need Help ",
  //   link: "/users/view/unapprove/need-help",
  // },
];

const merchant = [
  {
    id: 1732611279072,
    text: "Store",
    link: "/store",
    className: "Store-for-android",
    icon: (
      <img
        src={storeIcon}
        alt="labal"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img
        src={storeIconActive}
        alt="menu-defaults"
        className="h-6 w-10 mt-4 mb-4"
      />
    ),
  },
  {
    id: 1732611289107,
    text: "Manager ",
    link: "/manager",
    className: "Manager-for-android",
    icon: (
      <img
        src={ManagerIcon}
        alt="labal"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img
        src={ManagerIconActive}
        alt="menu-defaults"
        className="h-6 w-10 mt-4 mb-4"
      />
    ),
  },
];
const ManagerLink = [
  {
    // id: 82,
    text: "Store",
    link: "/store",
    className: "Store-for-android",
  },
];
const MerchantLink = [
  {
    // id: 82,
    text: "Store",
    link: "/store",
    className: "Store-for-android",
    icon: (
      <img
        src={storeIcon}
        alt="labal"
        className="h-6 w-10 mt-4 mb-4 hoverable-image"
      />
    ),
    activeIcon: (
      <img
        src={storeIconActive}
        alt="menu-defaults"
        className="h-6 w-10 mt-4 mb-4"
      />
    ),
  },
];
// }MerchantLink

export default SideMenu;
