import React from "react";

const ProductContent = () => {
  return (
    <>
      <div className="box">
        <div className="q-attributes-top-detail-section">
          <li>
            After you've made changes to your menu, select the option "Request
            Approval" to get admin approval to update your website.
          </li>
        </div>
      </div>
    </>
  );
};

export default ProductContent;
