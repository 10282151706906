import React, { useEffect, useState } from "react";
import NewItemCreatedBetweenList from "./NewItemCreatedBetweenList";
import DateRangeComponent from "../../../reuseableComponents/DateRangeComponent";
import { Grid } from "@mui/material";
import DashDateRangeComponent from "../../../reuseableComponents/InventoryReportDateComponent";
import { useAuthDetails } from "../../../Common/cookiesHelper";
import Config from "../../../Constants/Config";
import axios from "axios";
import { getCurrentDateRange } from "../../../helperFunctions/dataRange"
import { generateCSVDataAndHeaders } from "../../../helperFunctions/csvhelperforTable";

const NewItemCreatedBetweenMain = ({ setCSVData, setCSVHeaders }) => {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [productListData, setProductListData] = useState([]);

  const [selectedDateRange, setSelectedDateRange] = useState(getCurrentDateRange());
  const handleDateRangeChange = (dateRange) => {
    setProductListData([])
    setHasMore(true);
    setPage(1);
    setSelectedDateRange(dateRange);
    fetchNewItemCreatedBetweenData(1,dateRange);
  };
  const {
    LoginGetDashBoardRecordJson
  } = useAuthDetails();

  const fetchNewItemCreatedBetweenData = async (page,dateRange) => {
    setLoading(true)
    let data = {
      merchant_id:LoginGetDashBoardRecordJson?.data?.merchant_id,
      start_date: dateRange.start_date,
      end_date: dateRange.end_date,
      token_id: LoginGetDashBoardRecordJson?.token_id,
      login_type: LoginGetDashBoardRecordJson?.login_type,
      search_by: "",
      limit: "10",
      page:page
    };
    try{
    const response = await axios.post(
      `${Config.BASE_URL}${Config.NEW_ITEM_CREATED_BETWEEN_LIST}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          // Authorization: `Bearer ${token}`,
          Authorization: `Bearer ${LoginGetDashBoardRecordJson?.token}`,
        },
      }
    );

    if(response?.data && !response?.data?.status && response?.data?.page==1){
      setProductListData([])
    } 
    const products = response?.data?.report_data;

    if (products && products.length < 5) {
      setHasMore(false); 
    }
    if (products && products.length > 0 && page == 1) {
      setProductListData(products);
    } else if (products && products.length > 0 && page != 1) {
      setProductListData([...productListData, ...products]);
    }
    if(page >1 && !products){
      setHasMore(false); 
    }
    
    } catch (e){
      console.log("Error fetching products:", e);
    } finally {
        setLoading(false);
    }
  };

  const fetchMoreData = () => {
    // if (hasMore && productListData > 0 ) {
      setPage((prevPage) => prevPage + 1);
      const prevPage = page +1
      // fetchProductsData();
      fetchNewItemCreatedBetweenData(prevPage,selectedDateRange);
    // }
  };

  useEffect(() => {
    fetchNewItemCreatedBetweenData(1,selectedDateRange);
  }, []);

  useEffect(() => {
    // if (productListData.length > 0) {
      const headers = [
        { id: "created_on", name: "Date" },
        { id: "category", name: "Category" },
        { id: "item_name", name: "Item Name" },
        {id:"price",name:"Price"}
    ];
      const { CSVData, CSVHeaders } = generateCSVDataAndHeaders(productListData, headers);
      setCSVData(CSVData);
      setCSVHeaders(CSVHeaders);
    // }
  }, [setCSVData, setCSVHeaders, productListData]);

  return (
    <>
      <Grid container sx={{ mt: 3 }}>
        <DashDateRangeComponent onDateRangeChange={handleDateRangeChange} />
      </Grid>
      <NewItemCreatedBetweenList setProductListData={setProductListData} productData={productListData} scrollForProduct={fetchMoreData} hasMore={hasMore} loading={loading}/>
    </>
  );
};

export default NewItemCreatedBetweenMain;
