import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, VENDOR_SALES_REPORT } from "../../../../Constants/Config";
import { useAuthDetails } from "../../../../Common/cookiesHelper";

const initialState = {
  loading: false,
  vendorSalesReport: [],
  status: false,
  successMessage: "",
  error: "",
};

// Generate pening , fulfilled and rejected action type
export const fetchVendorSalesReport = createAsyncThunk(
  "vendorSalesReport/fetchVendorSalesReport",
  async (data) => {
    const { userTypeData } = useAuthDetails();
    try {
      const { token, ...otherUserData } = userTypeData;

      const response = await axios.post(
        BASE_URL + VENDOR_SALES_REPORT,
        { ...data, ...otherUserData },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status) {
        const arr = response.data.data;
        const status = response.data.status;
        return { arr, status };
      } else if (!response.data.status) {
        const arr = [];
        const status = false;
        return { arr, status };
      }
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const vendorSalesReportSlice = createSlice({
  name: "vendorSalesReport",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchVendorSalesReport.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchVendorSalesReport.fulfilled, (state, action) => {
      state.loading = false;
      state.vendorSalesReport = action.payload.arr;
      state.status = action.payload.status;
      state.error = "";
    });
    builder.addCase(fetchVendorSalesReport.rejected, (state, action) => {
      state.loading = false;
      state.vendorSalesReport = [];
      state.error = action.error.message;
    });
  },
});

export default vendorSalesReportSlice.reducer;
