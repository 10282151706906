import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../../Styles/EmployeeWorking.css";
import { fetchemployeewrkhrs } from "../../../Redux/features/Reports/EmployeeWorkinghrs/EmployeeWorkinghrsSlice";
import { useAuthDetails } from "../../../Common/cookiesHelper";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid } from "@mui/material";
import { priceFormate } from "../../../hooks/priceFormate";
import PasswordShow from "../../../Common/passwordShow";
import { SkeletonTable } from "../../../reuseableComponents/SkeletonTable";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../../../reuseableComponents/NoDataFound";
import useDelayedNodata from "../../../hooks/useDelayedNoData";
import { formatDate } from "../../../Constants/utils";

const StyledTable = styled(Table)(({ theme }) => ({
  padding: 2, // Adjust padding as needed
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#253338",
    color: theme.palette.common.white,
    fontFamily: "CircularSTDMedium !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "CircularSTDBook",
  },
  [`&.${tableCellClasses.table}`]: {
    fontSize: 14,
    fontFamily: "CircularSTDBook",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {},
  "& td, & th": {
    border: "none",
  },
}));

const tableRow = [
  "Date Worked",
  "Clock In",
  "Clock Out",
  "Total Worked (Hr)",
  "Total Break (Hr)",
  "Actual Worked (Hr)",
];

const Employeedetails = (props) => {
  const { LoginGetDashBoardRecordJson, LoginAllStore, userTypeData } =
    useAuthDetails();
  const { handleCoockieExpire, getUnAutherisedTokenMessage, getNetworkError } =
    PasswordShow();
  const dispatch = useDispatch();
  const [allEmpWorkingHrsData, setallEmpWorkingHrsData] = useState("");
  const showNoData = useDelayedNodata(Object.keys(allEmpWorkingHrsData));
  const AllEmpWorkingHrsDataState = useSelector(
    (state) => state.EmpWorkinghrsList
  );

  let AuthDecryptDataDashBoardJSONFormat = LoginGetDashBoardRecordJson;
  let merchant_id = AuthDecryptDataDashBoardJSONFormat?.data?.merchant_id;

  useEffect(() => {
    getEmployeewrkhrs();
  }, [props.selectedDateRange, props.selectedEmployeeList]);

  const getEmployeewrkhrs = async () => {
    try {
      if (props && props.selectedDateRange) {
        let data = {
          merchant_id,
          start_date: props.selectedDateRange.start_date,
          end_date: props.selectedDateRange.end_date,
          // employee_id: props.EmpId,
          employee_id:
            Array.isArray(props.selectedEmployeeList) &&
            props.selectedEmployeeList.length > 0 &&
            !props.allEmployeeSelected
              ? props.selectedEmployeeList.map((emp) => emp.id).join(",")
              : "All",
          ...userTypeData,
        };
        if (data) {
          await dispatch(fetchemployeewrkhrs(data)).unwrap();
        }
      }
    } catch (error) {
      if (error.status == 401 || error.response.status === 401) {
        getUnAutherisedTokenMessage();
        handleCoockieExpire();
      } else if (error.status == "Network Error") {
        getNetworkError();
      }
    }
  };

  useEffect(() => {
    if (
      !AllEmpWorkingHrsDataState.loading &&
      AllEmpWorkingHrsDataState?.employeewrkhrstData
    ) {
      props.setCSVFileName("employee_hours");
      props.setCSVHeader([
        { label: "Employee Name", key: "employee_name" },
        { label: "Date Worked", key: "work_date" },
        { label: "Clock In", key: "first_check_in_time" },
        { label: "Clock Out", key: "last_check_out_time" },
        { label: "Total Worked (Hr)", key: "total_seconds_worked" },
        { label: "Total Break (Hr)", key: "total_seconds_break" },
        { label: "Actual Worked (Hr)", key: "effective_seconds_worked" },
      ]);

      const csvData = [];

      const employees = Object.keys(
        AllEmpWorkingHrsDataState?.employeewrkhrstData
      );
      employees.forEach((employee) => {
        const employeeData =
          AllEmpWorkingHrsDataState?.employeewrkhrstData[employee];

        // now loop over all the employeeData...
        employeeData.forEach((row) => {
          const obj = {
            employee_name: employee,
            work_date: row.work_date ? formatDate(row.work_date) : "-",
            first_check_in_time: row.first_check_in_time,
            last_check_out_time: row.last_check_out_time,
            total_seconds_worked: priceFormate(
              (
                parseFloat(convertSecondsToHours(row.total_seconds_worked)) || 0
              ).toFixed(2)
            ),
            total_seconds_break: priceFormate(
              (
                parseFloat(convertSecondsToHours(row.total_seconds_break)) || 0
              ).toFixed(2)
            ),
            effective_seconds_worked: priceFormate(
              (
                parseFloat(
                  convertSecondsToHours(row.effective_seconds_worked)
                ) || 0
              ).toFixed(2)
            ),
          };

          csvData.push(obj);
        });

        // and then add a total row...
        const totalRow = {
          employee_name: "",
          work_date: "",
          first_check_in_time: "",
          last_check_out_time: "Total",
          total_seconds_worked: priceFormate(
            calTotalWork(employeeData).toFixed(2)
          ),
          total_seconds_break: priceFormate(
            parseFloat(calTotalBreak(employeeData)).toFixed(2)
          ),
          effective_seconds_worked: priceFormate(
            parseFloat(calTotalActualWork(employeeData)).toFixed(2)
          ),
        };

        csvData.push(totalRow);
      });

      console.log("csvData: ", csvData);

      props.setCSVData(csvData);
      setallEmpWorkingHrsData(AllEmpWorkingHrsDataState.employeewrkhrstData);
    } else {
      props.setCSVHeader([]);
      props.setCSVFileName("");
      props.setCSVData([]);
      setallEmpWorkingHrsData("");
    }
  }, [
    AllEmpWorkingHrsDataState,
    AllEmpWorkingHrsDataState.loading,
    AllEmpWorkingHrsDataState.ItemSalesData,
  ]);

  const convertSecondsToHours = (seconds) => {
    const hours = seconds / 3600;
    return hours;
  };

  const calTotalWork = (totalworkdata) => {
    if (!Array.isArray(totalworkdata)) {
      return 0;
    }
    return totalworkdata.reduce((total, workData) => {
      const parsedSeconds = parseFloat(workData.total_seconds_worked);
      const validSeconds = isNaN(parsedSeconds) ? 0 : parsedSeconds;
      const totalNew = total + convertSecondsToHours(validSeconds);

      return totalNew;
    }, 0);
  };

  const calTotalBreak = (totalbreakdata) => {
    if (!Array.isArray(totalbreakdata)) {
      return 0;
    }
    return totalbreakdata.reduce(
      (total, workData) =>
        total +
        convertSecondsToHours(parseFloat(workData.total_seconds_break ?? 0)),
      0
    );
  };
  const calTotalActualWork = (totalactualworkdata) => {
    if (!Array.isArray(totalactualworkdata)) {
      return 0;
    }

    return totalactualworkdata.reduce((total, workData) => {
      const parsedSeconds = parseFloat(workData.effective_seconds_worked);
      const validSeconds = isNaN(parsedSeconds) ? 0 : parsedSeconds;
      return total + convertSecondsToHours(validSeconds);
    }, 0); // Initial value of the accumulator is 0
  };

  // useEffect(() => {
  //   console.log("allEmpWorkingHrsData: ", allEmpWorkingHrsData);
  // }, [allEmpWorkingHrsData]);

  return (
    <>
      <div className="box">
        {AllEmpWorkingHrsDataState.loading ? (
          <>
            <Grid container className="box_shadow_div">
              <Grid item xs={12}>
                <div className="q-attributes-bottom-header">
                  <span>
                    <Skeleton />
                  </span>
                </div>
              </Grid>
              <SkeletonTable columns={tableRow} />
            </Grid>
          </>
        ) : (
          <>
            {allEmpWorkingHrsData &&
              Object.keys(allEmpWorkingHrsData).map((employeeName, index) => (
                <>
                  <Grid container className="box_shadow_div">
                    <Grid item xs={12}>
                      <Grid
                        sx={{ mx: { xs: 2, md: "unset" } }}
                        className="q-attributes-bottom-header"
                      >
                        <span>{employeeName}</span>
                      </Grid>
                      <TableContainer>
                        <StyledTable
                          sx={{ minWidth: 500 }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            {tableRow.map((row) => (
                              <StyledTableCell key={row}>{row}</StyledTableCell>
                            ))}
                          </TableHead>
                          <TableBody>
                            {Array.isArray(
                              allEmpWorkingHrsData[employeeName]
                            ) &&
                              allEmpWorkingHrsData[employeeName].length &&
                              allEmpWorkingHrsData[employeeName].map(
                                (workData, dataIndex) => (
                                  <>
                                    <StyledTableRow>
                                      <StyledTableCell>
                                        <p>
                                          {workData.work_date
                                            ? formatDate(workData.work_date)
                                            : "-"}
                                        </p>
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <p>{workData.first_check_in_time}</p>
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <p>{workData.last_check_out_time}</p>
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <p>
                                          {priceFormate(
                                            (
                                              parseFloat(
                                                convertSecondsToHours(
                                                  workData.total_seconds_worked
                                                )
                                              ) || 0
                                            ).toFixed(2)
                                          )}
                                        </p>
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <p>
                                          {priceFormate(
                                            (
                                              parseFloat(
                                                convertSecondsToHours(
                                                  workData.total_seconds_break
                                                )
                                              ) || 0
                                            ).toFixed(2)
                                          )}
                                        </p>
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <p>
                                          {priceFormate(
                                            (
                                              parseFloat(
                                                convertSecondsToHours(
                                                  workData.effective_seconds_worked
                                                )
                                              ) || 0
                                            ).toFixed(2)
                                          )}
                                        </p>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  </>
                                )
                              )}
                            {Array.isArray(
                              allEmpWorkingHrsData[employeeName]
                            ) && (
                              <StyledTableRow className="trBG_Color">
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell>
                                  <p className=" totalReport">Total</p>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <p className=" totalReport">
                                    {priceFormate(
                                      calTotalWork(
                                        allEmpWorkingHrsData[employeeName]
                                      ).toFixed(2)
                                    )}
                                  </p>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <p className=" totalReport">
                                    {priceFormate(
                                      parseFloat(
                                        calTotalBreak(
                                          allEmpWorkingHrsData[employeeName]
                                        )
                                      ).toFixed(2)
                                    )}
                                  </p>
                                </StyledTableCell>
                                <StyledTableCell>
                                  <p className=" totalReport">
                                    {priceFormate(
                                      parseFloat(
                                        calTotalActualWork(
                                          allEmpWorkingHrsData[employeeName]
                                        )
                                      ).toFixed(2)
                                    )}
                                  </p>
                                </StyledTableCell>
                              </StyledTableRow>
                            )}
                            {!Array.isArray(
                              allEmpWorkingHrsData[employeeName]
                            ) && (
                              <Grid container sx={{ padding: 2.5 }}>
                                <Grid item xs={12}>
                                  <p>No. Data found.</p>
                                </Grid>
                              </Grid>
                            )}
                          </TableBody>
                        </StyledTable>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </>
              ))}
          </>
        )}
      </div>
      <Grid sx={{ mt: 3.5 }}>
        {showNoData &&
          !Object.keys(allEmpWorkingHrsData).length &&
          !AllEmpWorkingHrsDataState.loading && <NoDataFound />}
      </Grid>
    </>
  );
};

export default Employeedetails;
