import React, { useState,useEffect } from "react";

import MainInstantDetails from "./MainInstantDetails";
import { useAuthDetails } from "../../../Common/cookiesHelper";
import DateRangeComponent from "../../../reuseableComponents/DateRangeComponent";
import { Grid } from "@mui/material";
import InventoryTable from "../InventoryReport/InventoryTable";
import DashDateRangeComponent from "../../../reuseableComponents/InventoryReportDateComponent";
import Config from "../../../Constants/Config";
import axios from 'axios';
import { getCurrentDateRange } from "../../../helperFunctions/dataRange"
import { generateCSVDataAndHeaders } from "../../../helperFunctions/csvhelperforTable";


const InstantActvity = ({ setCSVData, setCSVHeaders }) => {
  const { LoginGetDashBoardRecordJson, LoginAllStore, userTypeData } =
    useAuthDetails();
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1); // Track current page number
    const [poActivityListData, setPoActivityListData] = useState([]);
  // const [filteredData, setFilteredData] = useState([]);
  const [filteredData, setFilteredData] = useState(getCurrentDateRange());
    const handleDateRangeChange = (dateRange) => {
      setPoActivityListData([])
      setHasMore(true);
      setPage(1);
      setFilteredData(dateRange); 
      fetchProductsData(1,dateRange)
    };

  const fetchProductsData = async (page,dateRange) => {

    try {
      setLoading(true)
      const payload = {
        merchant_id: LoginGetDashBoardRecordJson?.data?.merchant_id,
        token_id: LoginGetDashBoardRecordJson?.token_id,
        login_type: LoginGetDashBoardRecordJson?.login_type,
        limit: 10,
        page: page,
        start_date: dateRange.start_date,
        end_date: dateRange.end_date,  
      }
      const response = await axios.post(
        `${Config.BASE_URL}${Config.INSTANT_ACTIVITY_REPORT}`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `${LoginGetDashBoardRecordJson?.token}`
          },
        }
      );

      
      if(!response?.data?.status && response?.data?.page==1){
        setPoActivityListData([])
      }
      const products = response?.data?.result;
      if (products && products.length < 5) {
        setHasMore(false); 
      }
      if (products && products.length > 0 && page == 1) {
        setPoActivityListData(products);
      } else if (products && products.length > 0 && page != 1) {
        setPoActivityListData([...poActivityListData, ...products]);
      }
      if(page >1 && !products){
          setHasMore(false); 
      }
      return products;
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
       setLoading(false); 
    }
  }
  const fetchMoreData = () => {
    setPage(prevPage => prevPage + 1);
    fetchProductsData(page + 1,filteredData);
  };


  useEffect(() => {
    fetchProductsData(page,filteredData);
  }, []);


  useEffect(() => {
    // if (poActivityListData.length > 0) {
      const headers = [
        { id: "title", name: "Instant PO Info" },
        { id: "emp_name", name: "Source" },
        { id: "current_qty", name: "Before Adjust Qty" },
        {id:"qty",name:"Adjust Qty"},
        {id:"after_qty",name:"After Adjust Qty"},
        {id:"price",name:"Per Item Cost"},
        {id:"totalcost",name:"Total Cost"}
    ];
      const { CSVData, CSVHeaders } = generateCSVDataAndHeaders(poActivityListData, headers);
      setCSVData(CSVData);
      setCSVHeaders(CSVHeaders);
    // } 
  }, [setCSVData, setCSVHeaders, poActivityListData]);
  return (
    <>
      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <DashDateRangeComponent onDateRangeChange={handleDateRangeChange} />
        </Grid>
      </Grid>
      <MainInstantDetails setPoActivityListData={setPoActivityListData} instantactivityDataState={poActivityListData}  scrollForProduct={fetchMoreData}
        hasMore={hasMore} loading={loading} />
    </>
  );
};

export default InstantActvity;
