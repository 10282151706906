import React from "react";
import EmployeelistReport from "./EmployeelistReport";

const MainEmployeelist = ({
  hide = false,
  setCSVData,
  setCSVHeader,
  setCSVFileName,
}) => {
  return (
    <>
      <div className="q-order-main-page">
        <EmployeelistReport
          hide={hide}
          setCSVData={setCSVData}
          setCSVHeader={setCSVHeader}
          setCSVFileName={setCSVFileName}
        />
      </div>
    </>
  );
};

export default MainEmployeelist;
