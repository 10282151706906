import React from "react";
import InventoryView from "./InventoryView";
import { Grid } from "@mui/material";

function MainInventory() {
  return (
    <>

      <Grid container sx={{pb:{xs:10}}} className="q-category-main-page">
        <InventoryView />
      </Grid>
    </>
  );
}

export default MainInventory;
