import React, { useState, useEffect, useRef, useMemo } from "react";
import { Grid, TextField } from "@mui/material";
import InputTextSearch from "../../../reuseableComponents/InputTextSearch";
import SelectDropDown from "../../../reuseableComponents/SelectDropDown";
import { useSelector, useDispatch } from "react-redux";
import { useAuthDetails } from "../../../Common/cookiesHelper";
import PasswordShow from "../../../Common/passwordShow";
import {
  fetchStoresData,
  fetchPaylistData,
} from "../../../Redux/features/SingleStore/singleStoresSlice";
const MainOnline = ({
  onFilterDataChange,
  searchId,
  setSearchId,
  order_env,
  setSelectedTransaction,
  selectedTransaction,
}) => {
  const [transactionsList, setTransactionsList] = useState([]);

  // const transactionsList = [
  //   {
  //     title: "Both",
  //   },
  //   {
  //     title: "Cash",
  //   },
  //   {
  //     title: "Credit Card",
  //   },
  // ];

  const orderStatusList = [
    {
      title: "New",
    },
    {
      title: "Completed",
    },
    {
      title: "Failed",
    },
  ];

  const dispatch = useDispatch();
  const { LoginGetDashBoardRecordJson, userTypeData } = useAuthDetails();
  // const [storeData, setStoreData] = useState([]);

  const { handleCoockieExpire, getUnAutherisedTokenMessage, getNetworkError } =
    PasswordShow();
  const [storeData, setStoreData] = useState([]);

  const singleStoresDataState = useSelector((state) => state.singleStores);

  let merchant_id = LoginGetDashBoardRecordJson?.data?.merchant_id;

  useEffect(() => {
    getStoreData();
  }, [merchant_id]);
  const getStoreData = async () => {
    try {
      const Storedata = {
        merchant_id: merchant_id,
        ...userTypeData,
      };
      await dispatch(fetchStoresData(Storedata)).unwrap();
    } catch (error) {
      if (error.status == 401) {
        getUnAutherisedTokenMessage();
        handleCoockieExpire();
      } else if (error.status == "Network Error") {
        getNetworkError();
      }
    }
  };
  useEffect(() => {
    if (!singleStoresDataState.loading && singleStoresDataState.storesData) {
      setStoreData(singleStoresDataState.storesData);
    }
  }, [
    singleStoresDataState,
    singleStoresDataState.loading,
    singleStoresDataState.storesData,
  ]);
  useEffect(() => {
    if (storeData?.Paymentmode?.cc_payment !== undefined) {
      switch (storeData?.Paymentmode?.cc_payment) {
        case "0":
          setTransactionsList([{ title: "Cash" }]);
          setSelectedTransaction("Cash");
          break;
        case "1":
          setTransactionsList([{ title: "Credit Card" }]);
          setSelectedTransaction("Credit Card");

          break;
        case "2":
          setTransactionsList([
            { title: "Both" },
            { title: "Cash" },
            { title: "Credit Card" },
          ]);
          setSelectedTransaction("Both");

          break;
        default:
          setTransactionsList([{ title: "Cash" }]);
          setSelectedTransaction("Cash");

          break;
      }
    }
  }, [storeData?.Paymentmode?.cc_payment, merchant_id]);

  const handleSearch = () => {
    // console.log("Search ID:", searchId);
  };

  const [isTablet, setIsTablet] = useState(false);
  // const [selectedEmployee, setSelectedEmployee] = useState("All");
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("New");

  // const [employeeDropdownVisible, setEmployeeDropdownVisible] = useState(false);
  const [transactionDropdownVisible, setTransactionDropdownVisible] =
    useState(false);
  const [orderStatusDropdownVisible, setOrderStatusDropdownVisible] =
    useState(false);

  const toggleDropdown = (dropdown) => {
    switch (dropdown) {
      // case "employee":
      //   setEmployeeDropdownVisible(!employeeDropdownVisible);
      //   break;
      case "transaction":
        setTransactionDropdownVisible(!transactionDropdownVisible);
        break;
      case "orderStatus":
        setOrderStatusDropdownVisible(!orderStatusDropdownVisible);
        break;
      default:
        break;
    }
  };

  const handleOptionClick = (option, dropdown) => {
    switch (dropdown) {
      case "transaction":
        setSelectedTransaction(option.title);
        setTransactionDropdownVisible(false);
        break;
      case "orderStatus":
        setSelectedOrderStatus(option.title);
        setOrderStatusDropdownVisible(false);
        break;
      default:
        break;
    }
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setTransactionDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const orderStatus = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (orderStatus.current && !orderStatus.current.contains(event.target)) {
        setOrderStatusDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    onFilterDataChange(selectedTransaction, selectedOrderStatus, searchId);
  }, [selectedTransaction, selectedOrderStatus, searchId]);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 885);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Grid container className="px-2 sm:px-5">
        <Grid item xs={12}>
          <Grid container className="mt-5">
            <Grid item xs={12} className="">
              <InputTextSearch
                placeholder="Search orders by order ID, last 4 digits on payment card, or invoice ID"
                value={searchId}
                handleChange={setSearchId}
                handleSearchButton={handleSearch}
              />
            </Grid>
          </Grid>
          {!searchId && (
            <>
              <Grid container className="mt-5 ">
                <Grid item className="mt_card_header q_dashbaord_netsales ">
                  <h1 className="">Filter By</h1>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {storeData?.Paymentmode?.cc_payment === "2" && (
                  <Grid item xs={12} sm={6} md={4}>
                    <label htmlFor="transactionFilter">Payment Type</label>
                    <SelectDropDown
                      sx={{ pt: 0.5 }}
                      listItem={transactionsList}
                      title={"title"}
                      onClickHandler={handleOptionClick}
                      selectedOption={selectedTransaction}
                      dropdownFor={"transaction"}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4}>
                  <label htmlFor="orderStatusFilter">Order Status</label>
                  <SelectDropDown
                    sx={{ pt: 0.5 }}
                    heading={""}
                    listItem={orderStatusList}
                    title={"title"}
                    onClickHandler={handleOptionClick}
                    selectedOption={selectedOrderStatus}
                    dropdownFor={"orderStatus"}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default MainOnline;
