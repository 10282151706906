import React from "react";

import { BASE_URL } from "../../Constants/Config";
import { Grid } from "@mui/material";

const ImportData = () => {
  return (
    <>
      <div className="box">
        <div style={{padding:0}} className="q-attributes-top-detail-section">
          <Grid sx={{py:2.5,px:{xs:1,sm:2.5}}} className="flex flex-wrap justify-between gap-2">
            <ul>
              <li>
                {" "}
                Download and check sample CSV file for proper file format and
                fields.
              </li>
              <li>
                {" "}
                Please enter variant names which are created for the store.
                (Also, they are case sensitive).
              </li>
            </ul>

            <div className="col-span-4">
              <button
                className="csv_btn"
                onClick={() => {
                  // Handle the download functionality here
                  window.location.href =
                    BASE_URL + "upload/SampleCSV/SampleProductsCSVnew.csv";
                }}
              >
                Sample CSV Download
              </button>
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default ImportData;
