import React from "react";
import MainInStore from "./InstoreOrder/MainInStore";

const MainOrder = () => {
  return (
    <>
      <MainInStore />
    </>
  );
};

export default MainOrder;
