import { Grid, tooltipClasses, Typography } from "@mui/material";
import React, { useState } from "react";
import LeftArrow from "../Assests/Dashboard/Left.svg";
import { Link, useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { Tooltip, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { useMediaQuery } from '@mui/material';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#f5f5f9",
    "&::before": {
      border: "1px solid #0a64f9",
      backgroundColor: "#fff",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, 0.87)",
  },
  [`&[data-popper-placement*="right"] .${tooltipClasses.arrow}`]: {
    marginLeft: "0.14em",
    marginBottom: "0px",
  },
  [`&[data-popper-placement*="top"] .${tooltipClasses.arrow}`]: {
    marginBottom: "-0.23em",
  },
  [`&[data-popper-placement*="top"] .MuiTooltip-tooltip`]: {
    marginBottom: "0px !important", // Adjust as needed
  },
 
}));
export default function SwitchToBackButton({ linkTo, title, productId }) {
  const isSmallScreen =  useMediaQuery('(max-width:600px)');

  const [close, setClose] = useState(true);
  const navigate = useNavigate();
  const [open, setOpen] = useState(true); // Start with the tooltip visible

  const handleCancel = () => {
    setOpen(false); // Hide the tooltip when cancel is clicked
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setClose((prev) => !prev);
  };

  const redirectOnDuplicate = () => {
    navigate(`/inventory/products/duplicate-product/${productId?.id}`);
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          sx={{ px: { xs: 1, sm: 2.5 } }}
          className="q-add-categories-section-header"
        >
          {linkTo === "/inventory/products" && close ? (
            <CustomTooltip
            PopperProps={{
              style: { zIndex: 1 }, // Adjust as needed
            }}
              sx={{
                backgroundColor: "transparent", // Remove background color
                boxShadow: "none", // Optional: remove shadow if desired
              }}
              title={
                <div
                  className="product-error-note-tooltip"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="body2">
                    Special characters not allowed
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={handleCancel}
                    style={{ marginLeft: 8 }}
                  >
                    <CancelIcon fontSize="small" />
                  </IconButton>
                </div>
              }
              open={open}
              placement={isSmallScreen ? "top" : "right"}
            >
              <Link
                to={linkTo}
                className={
                  linkTo === "/inventory/products"
                    ? "product-heading-title"
                    : ""
                }
              >
                <img src={LeftArrow} alt="Add-New-Vendors" />
                <span>{title}</span>
              </Link>
            </CustomTooltip>
          ) : (
            <Link
              to={linkTo}
              className={
                linkTo === "/inventory/products" ? "product-heading-title" : ""
              }
            >
              <img src={LeftArrow} alt="Add-New-Vendors" />
              <span>{title}</span>
            </Link>
          )}

          {/* {linkTo === "/inventory/products" && close ? (
            <div className="product-error-note">
              <span className="whitespace-nowrap">
                Special characters not allowed
              </span>
              <CancelIcon onClick={handleClose} />
            </div>
          ) : (
            ""
          )} */}
          {title === "Edit Product" ? (
            <button
              className="quic-btn quic-btn-bulk-edit duplicate-product"
              onClick={() => redirectOnDuplicate()}
            >
              Duplicate Product
            </button>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
