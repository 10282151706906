export const generateCSVDataAndHeaders = ( productListData, initialColumns ) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        }) 
    };
  // console.log("===productListDataproductListDataproductListData=====", initialColumns);
  // return;
        const CSVData = productListData.map(product => ({
            name: product?.name || '',
            closing_inventory: product?.closing_inventory ? parseFloat(product.closing_inventory).toFixed(2) : '',
            items_sold_per_day: product?.items_sold_per_day ? parseFloat(product?.items_sold_per_day).toFixed(2) :'',
            items_sold: product?.items_sold ? parseFloat(product?.items_sold).toFixed(2) :'',
            inbound_inventory: product?.inbound_inventory ? parseFloat(product?.inbound_inventory).toFixed(2) :'',
            inventory_days_cover: product?.inventory_days_cover ? parseFloat(product.inventory_days_cover).toFixed(2) : '',
            avg_cost: product?.avg_cost ? parseFloat(product.avg_cost).toFixed(2) : '',
            brand: product?.brand || '',
            vendor: product?.vendor || '',
            category: product?.category || '',
            gross_profit: product?.gross_profit ? parseFloat(product.gross_profit).toFixed(2) : '',
            sale_margin: product?.sale_margin ? parseFloat(product.sale_margin).toFixed(2) : '',
            customer_count: product?.customer_count ? parseFloat(product?.customer_count).toFixed(2) :'',
            sale_count: product?.sale_count ? parseFloat(product?.sale_count).toFixed(2) :'',
            inventory_cost: product?.inventory_cost ? parseFloat(product.inventory_cost).toFixed(2) : '',
            avg_items_per_sale: product?.avg_items_per_sale ? parseFloat(product?.avg_items_per_sale).toFixed(2) :'',
            sale_discounted: product?.sale_discounted ? parseFloat(product.sale_discounted).toFixed(2) : '',
            avg_sale_value: product?.avg_sale_value ? parseFloat(product.avg_sale_value).toFixed(2) : '',
            cost_goods_sold: product?.cost_goods_sold ? parseFloat(product.cost_goods_sold).toFixed(2) : '',
            retail_value: product?.retail_value ? parseFloat(product.retail_value).toFixed(2) : '',
            start_date_inventory: product?.start_date_inventory ? parseFloat(product?.start_date_inventory).toFixed(2) :'',
            reorder_point: product?.reorder_point ? parseFloat(product?.reorder_point).toFixed(2) :'',
            reorder_amount: product?.reorder_amount ? parseFloat(product?.reorder_amount).toFixed(2) :'',
            return_count: product?.return_count ? parseFloat(product?.return_count).toFixed(2) :'',
            inventory_returns: product?.inventory_returns ? parseFloat(product?.inventory_returns).toFixed(2) :'',
            sell_through_rate: product?.sell_through_rate ? parseFloat(product.sell_through_rate).toFixed(2) : '',
            created: product?.created ? formatDate(product.created) : '',
            firstSale: product?.firstSale ? formatDate(product.firstSale) : '',
            lastSale: product?.lastSale ? formatDate(product.lastSale) : '',
            last_received: product?.last_received || '',
            tag: product?.tag || '',
            current_inventory:  product?.current_inventory ? parseFloat(product.current_inventory).toFixed(2) : '',
          }));
          
  
    const headers = [
      { id: "name", name: "Product Name" },
      { id: "closing_inventory", name: "Closing Inventory" },
      { id: "items_sold_per_day", name: "Items sold per day" },
      { id: "items_sold", name: "Items sold" },
      { id: "inbound_inventory", name: "Inbound Inventory" },
      { id: "inventory_days_cover", name: "Days Cover" },
      { id: "avg_cost", name: "Avg. cost" },
      { id: "brand", name: "Brand" },
      { id: "vendor", name: "Vendor" },
      { id: "category", name: "Category" },
      { id: "gross_profit", name: "Gross Profit" },
      { id: "sale_margin", name: "Margin" },
      { id: "customer_count", name: "Customer count" },
      { id: "sale_count", name: "Sale count" },
      { id: "inventory_cost", name: "Inventory cost" },
      { id: "avg_items_per_sale", name: "Avg. items per sale" },
      { id: "sale_discounted", name: "Discounted (%)" },
      { id: "avg_sale_value", name: "Avg. sale value" },
      { id: "cost_goods_sold", name: "Cost of goods sold" },
      { id: "retail_value", name: "Retail value (excl. tax)" },
      { id: "start_date_inventory", name: "Start date inventory" },
      { id: "reorder_point", name: "Reorder point" },
      { id: "reorder_amount", name: "Reorder amount" },
      { id: "return_count", name: "Return count" },
      { id: "inventory_returns", name: "Returns (%)" },
      { id: "sell_through_rate", name: "Sell-through rate" },
      { id: "created", name: "Created" },
      { id: "firstSale", name: "First sale" },
      { id: "lastSale", name: "Last sale" },
      { id: "last_received", name: "Last received" },
      { id: "tag", name: "Tag" },
      { id: "current_inventory", name: "Current Inventory" }
    ];
  
    const CSVHeaders = initialColumns.map(column => ({
      label: column.name,
      key: column.id,
    }));
  
    return { CSVData, CSVHeaders };
  };
  