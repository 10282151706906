import { Grid, Skeleton as SkeletonMui } from "@mui/material";
import React, { useMemo } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { priceFormate } from "../../hooks/priceFormate";

const CustomTooltip = ({ payload, title }) => {
  // console.log("payload: ", payload);
  // console.log("title: ", title);
  if (payload && payload[0] && payload[0]["payload"]) {
    // let uv =
    //   parseFloat(payload[0]["payload"]?.uv) > 0
    //     ? parseFloat(payload[0]["payload"]?.uv)
    //     : 0;

    let uv = parseFloat(payload[0]["payload"]?.uv)
      ? parseFloat(payload[0]["payload"]?.uv)
      : 0;

    // if (title === "Revenue") {
    //   uv = `$${uv > 0 ? priceFormate(uv.toFixed(2)) : 0}`;
    // } else if (title === "Gross Profit") {
    //   uv = `$${uv > 0 ? priceFormate(uv.toFixed(2)) : 0}`;
    // } else if (title === "Discounted") {
    //   uv = `$${uv > 0 ? priceFormate(uv.toFixed(2)) : 0}`;
    // } else if (title === "Discounted %") {
    //   uv = `${uv > 0 ? uv.toFixed(2) : 0}%`;
    // } else if (title === "Avg. sale value") {
    //   uv = `$${uv > 0 ? priceFormate(uv.toFixed(2)) : 0}`;
    // } else if (title === "Avg. items per sale") {
    //   uv = `${uv > 0 ? uv.toFixed(2) : 0}`;
    // }

    if (title === "Revenue") {
      uv = `$${uv ? priceFormate(uv.toFixed(2)) : 0}`;
    } else if (title === "Gross Profit") {
      uv = `$${uv ? priceFormate(uv.toFixed(2)) : 0}`;
    } else if (title === "Discounted") {
      uv = `$${uv ? priceFormate(uv.toFixed(2)) : 0}`;
    } else if (title === "Discounted %") {
      uv = `${uv ? uv.toFixed(2) : 0}%`;
    } else if (title === "Avg. sale value") {
      uv = `$${uv ? priceFormate(uv.toFixed(2)) : 0}`;
    } else if (title === "Avg. items per sale") {
      uv = `${uv ? uv.toFixed(2) : 0}`;
    }

    // else if (title === "Sales Count") {
    //   uv = parseFloat(uv);
    // }

    return (
      <div className="custom-tooltip auto">
        <p>{payload[0]["payload"]?.name}</p>
        <p>{uv}</p>
      </div>
    );
  }
};

const data = [
  {
    name: "Page A",
    uv: 1560,
  },
  {
    name: "Page B",
    uv: 7682,
  },
  {
    name: "Page C",
    uv: 9680,
  },
  {
    name: "Page D",
    uv: -714,
  },
  {
    name: "Page E",
    uv: 0,
  },
  {
    name: "Page F",
    uv: 4735,
  },
  {
    name: "Page G",
    uv: -440,
  },
];

export default function SpikeCharts({
  title,
  growth,
  mainOutlet,
  amount,
  activeType,
  xAxisData,
  maxValue,
  minValue,
  yAxisOptions,
  type,
  formatFunction,
  loading = false,
  presentDate,
}) {
  const filterBy = useMemo(() => {
    return activeType === "Day"
      ? "day"
      : activeType === "Week"
        ? "week"
        : "month";
  }, [activeType]);

  const titleHanlde = ["Discounted %", "Gross Profit"];

  // console.log("title: ", title);
  // console.log("xAxisData: ", xAxisData);
  // console.log("yAxisOptions: ", yAxisOptions);
  // console.log("-----------------------------------");

  return (
    <Grid container className="box_shadow_div" sx={{ p: 2.5, m: 0 }}>
      {loading ? (
        <SkeletonMui
          sx={{ height: 300, width: "100%", transform: "scale(1)" }}
        />
      ) : (
        <>
          <Grid
            item
            xs={12}
            sx={{ px: { xs: 1, md: 0 } }}
            className="flex justify-between"
          >
            <p className="CircularSTDMedium-18px">{title}</p>
            {loading ? (
              <Skeleton width="200px" />
            ) : (
              growth && (
                <div className="flex items-center gap-1">
                  {growth > 0 ? (
                    <>
                      <FaCaretUp className="text-[#1EC285]" />
                      <p className="CircularSTDBook-15px text-[#1EC285]">{`${growth}% up previous ${filterBy}`}</p>
                    </>
                  ) : growth < 0 ? (
                    <>
                      <FaCaretDown className="text-[#ff3737]" />
                      <p className="CircularSTDBook-15px text-[#ff3737]">{`${Math.abs(
                        growth
                      )}% down previous ${filterBy}`}</p>
                    </>
                  ) : null}
                </div>
              )
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ px: { xs: 1, md: 0 } }}
            className="flex items-center "
          >
            {loading ? (
              <Skeleton width="150px" height="78.5px" />
            ) : (
              <>
                <p className=" CircularSTDMedium-32px">{amount}</p>
                <Link
                  className="CircularSTDMedium-18px ml-2 pt-3 text-blue-600 underline text-base"
                  // to="/dashboard-chart-view-reports"
                  to={{
                    pathname: "/dashboard-chart-view-reports",
                  }}
                  state={{
                    activeType: activeType,
                    title: title,
                    currentPresentDate: presentDate,
                  }}
                >
                  {titleHanlde.includes(title) ? "" : "View Reports"}
                </Link>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ px: { xs: 1, md: 0 } }}
            className="flex justify-between bg-[#FBFBFB] p-2"
          >
            <p className="CircularSTDMedium-15px">Main Outlet</p>
            <p className="CircularSTDBook-15px text-[#0A64F9]">
              {loading ? <Skeleton width="100px" /> : mainOutlet}
            </p>
          </Grid>
          {loading ? (
            <Grid item xs={12}>
              <Skeleton width="100%" height={260} />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart
                  data={xAxisData}
                  // data={data}
                  margin={{
                    right: 50,
                    left:
                      yAxisOptions.sort((a, b) => b - a)[0]?.toString()
                        ?.length * 2,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  {/* <XAxis dataKey="name" /> */}
                  {/* <YAxis
            padding={{ top: 15 }}
            type={type}
            domain={[minValue, maxValue]}
            // ticks={yAxisOptions}
            tickFormatter={(tickItem) => formatFunction(tickItem)}
          /> */}
                  <Line dataKey="uv" stroke="#0A64F9" strokeWidth={2} />
                  <Tooltip content={<CustomTooltip title={title} />} />
                  <XAxis dataKey="name" />
                  <YAxis
                    padding={{ top: 15 }}
                    type={type}
                    domain={[minValue, maxValue]}
                    tickFormatter={(tickItem) => formatFunction(tickItem)}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}
