import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, GET_STORE_DATA } from '../../../Constants/Config';
import { useAuthDetails } from "../../../Common/cookiesHelper";

const initialState = {
    loading: false,
    storesData: [],
    error: '',
};

// Generate pening , fulfilled and rejected action type

export const fetchStoresData = createAsyncThunk(
    "singleStores/fetchStoresData",
    async (data, { rejectWithValue }) => {

      const { userTypeData } = useAuthDetails();
      try {
        const { token, ...otherUserData } = userTypeData;
        const response = await axios.post(
          BASE_URL + GET_STORE_DATA,
          { ...data, ...otherUserData },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === 200) {
          return response.data.message;
        }else{
            return response.data
        }
      } catch (error) {
        // throw new Error(error.response.data.message);
        const customError = {
          message: error.message,
          status: error.response ? error.response.status : "Network Error",
          data: error.response ? error.response.data : null,
        };
        return rejectWithValue(customError);
      }
    }
  );

const singleStoresSlice = createSlice({
    name: 'singleStores',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchStoresData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchStoresData.fulfilled, (state, action) => {
            state.loading = false;
            state.storesData = action.payload;
            state.error = '';
        });
        builder.addCase(fetchStoresData.rejected, (state, action) => {
            state.loading = false;
            state.storesData = {};
            state.error = action.error.message;
        });
    },
});

export default singleStoresSlice.reducer;
