const ThreeDecimalInputHelperFun = (value) => {
    let val = value.replace(/[^\d]/g, "");
  
    // Return "00.000" if the value is empty
    if (val === "") {
      return "";
    }
    val = val.replace(/^0+/, "");
    // Ensure the string has enough digits, prepending zeros if necessary
    while (val.length < 4) {
      val = "0" + val; // Prepend zeros until the length is 5
    }
  
    const integerPart = val.slice(0, val.length - 3);
    const decimalPart = val.slice(val.length - 3);
    return `${integerPart}.${decimalPart}`;
  };
  
  export default ThreeDecimalInputHelperFun;
