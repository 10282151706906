import React, { useEffect, useRef, useState } from "react";
import { priceFormate } from "../hooks/priceFormate";
import { formatCurrency } from "../Constants/utils";
import NoDataFound from "../reuseableComponents/NoDataFound";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

export const CommonTable = ({
  isShow,
  childHeader,
  childrenBody,
  childFooter,
  loading,
}) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const tableRef = useRef(null);
  const tableContainerRef = useRef(null);
  const isTabletNav = useMediaQuery("(max-width:1024px)");
  const isMenuOpenRedux = useSelector((state) => state.NavBarToggle.isMenuOpen);
  const isMobileNav = useMediaQuery("(min-width:767px)");
  const isOpenedByHover = useSelector(
    (state) => state.NavBarToggle.isOpenedByHover
  );
  const checkOverflow = () => {
    if (tableRef.current) {
      const navAdjustment = isMenuOpenRedux ? -276 : -80;
      const tableWidth = tableRef.current.offsetWidth;
      const windowWidth = window.innerWidth + navAdjustment;
      setIsOverflowing(tableWidth < windowWidth);
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [isMenuOpenRedux, isTabletNav]);
  return (
    <div className={`commonTable ${!isOverflowing ? "overflow-auto" : ""}`}>
      <div className={`table-container `}>
        <table ref={tableRef} className="table-overflow">
          <thead className="table-header">
            <tr className="tableStickyHead">{isShow ? childHeader : null}</tr>
          </thead>

          {loading ? (
            <tbody className="table-body">
              {[1, 2, 3, 4, 5, 6].map((row) => (
                <tr key={row}>
                  {childHeader.map((col) => (
                    <td key={col}>
                      <Skeleton />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ) : (
            <>
              <tbody className="table-body">
                {isShow ? childrenBody : null}
              </tbody>
              {childrenBody?.length > 0 && (
                <tfoot className="table-footer">
                  {isShow ? childFooter : null}
                </tfoot>
              )}
            </>
          )}
        </table>
        {!childrenBody?.length > 0 && !loading && <NoDataFound table={true} />}
      </div>
    </div>
  );
};

export default CommonTable;
