import React, { useState, useRef, useEffect, Fragment } from "react";
import { MenuItem, InputBase, Popover, Checkbox } from "@mui/material";
import { FiChevronDown } from "react-icons/fi";

export default function SelectDropDownWithCheckBox({
  label = "",
  options = [],
  onClickHandler,
  selectedOption,
  title,
  selectedOptions = [], // Prop to get the selected options from the parent component
  allOptionSelected = false,
}) {
  const [anchorEl, setAnchorEl] = useState(null); // State to control dropdown
  const [anchorElWidth, setAnchorElWidth] = useState(null);

  const [searchText, setSearchText] = useState(""); // State for search input
  const inputRef = useRef(null); // Reference for focusing input
  const isDropdownOpen = Boolean(anchorEl);
  // Open dropdown menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setAnchorElWidth(event.currentTarget.clientWidth);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  // Close dropdown menu
  const handleMenuClose = () => {
    setAnchorEl(null);
    setSearchText(""); // Clear the search text when closing the menu
  };

  // Handle search text change
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  // Handle option click

  // Update anchorElWidth only if it has a valid clientWidth

  // Filter options based on search input
  const filteredOptions = options?.filter((option) =>
    option[title].toLowerCase().includes(searchText.toLowerCase())
  );
  const rotateClass = (open) => (open ? "-rotate-180" : "rotate-0");
  // console.log("filteredOptions", filteredOptions);

  // console.log("anchorElWidth", anchorElWidth);
  return (
    <div className="border border-[#E3E3E3] rounded mt-1">
      {/* Trigger dropdown */}
      <div
        onClick={handleMenuOpen}
        className="flex items-center justify-between cursor-pointer p-2"
      >
        <p
          className={
            selectedOptions.length > 0 ? "text-[]" : "text-[#000000de]"
          }
        >
          {selectedOptions.length > 0 ? selectedOptions.join(", ") : label}
        </p>
        <div className="">
          <FiChevronDown
            size={20}
            className={`transition-transform duration-500 ${rotateClass(
              Boolean(anchorEl)
            )}`}
            // src={upArrow}
          />
        </div>
      </div>

      {/* Dropdown menu */}
      <Popover
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleMenuClose}
        PaperProps={{
          className: "custom-scroll no-top-shadow",
          style: {
            width: anchorElWidth,
            maxHeight: 300,
            marginTop: 6,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
        }}
        MenuListProps={{
          style: { paddingTop: 10 },
        }}
      >
        {/* Search input */}
        <MenuItem
          disableTouchRipple
          sx={{
            "&.Mui-focusVisible": {
              backgroundColor: "#fff !important", // Remove background color when focused
            },
            "&:hover": {
              backgroundColor: "#fff", // Remove or customize hover background color
            },
            position: "sticky",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 9,
            background: "#fff",
            px: 1,
          }}
        >
          <InputBase
            sx={{
              background: "#fff",
              "&.Mui-focused": {
                background: "#fff", // Remove background color when focused
              },
              "&.Mui-focusVisible": {
                backgroundColor: "#fff !important", // Remove background color when focused
              },
              "&:hover": {
                backgroundColor: "#fff !important", // Remove or change the hover background color
              },
            }}
            placeholder="Search..."
            onChange={handleSearchChange}
            value={searchText}
            // inputRef={inputRef}
            fullWidth
          />
        </MenuItem>
        {options?.length > 1 && 
          <MenuItem sx={{ p: 0 }} onClick={() => onClickHandler("All")}>
          <Checkbox checked={allOptionSelected} />
          All
        </MenuItem>
        }
        
        {/* Render filtered options */}

        {filteredOptions.length > 0 ? (
          filteredOptions.map((option, index) => (
            <Fragment key={index}>
              <MenuItem
                sx={{ p: 0 }}
                key={index}
                onClick={() => onClickHandler(option)}
              >
                <Checkbox
                  // checked={selectedOption?.some(
                  //   (selected) => selected[title] === option[title]
                  // )}
                  checked={selectedOption?.some((item) =>
                    item.id
                      ? item.id === option.id
                      : item[title] === option[title]
                  )}
                />
                <span className="CircularSTDBook-15px">
                  {option[title]}
                  </span>
              </MenuItem>
            </Fragment>
          ))
        ) : (
          <MenuItem disabled>No options found</MenuItem>
        )}
      </Popover>
    </div>
  );
}
