import React, { useState, useEffect } from "react";

import { BASE_URL, TAXE_CATEGORY_LIST } from "../../../Constants/Config";
import axios from "axios";
import { useAuthDetails } from "../../../Common/cookiesHelper";
import { Grid } from "@mui/material";
import SelectDropDown from "../../../reuseableComponents/SelectDropDown";
import CustomHeader from "../../../reuseableComponents/CustomHeader";
import PasswordShow from "../../../Common/passwordShow";
import InputTextSearch from "../../../reuseableComponents/InputTextSearch";
import { useDispatch ,useSelector} from "react-redux";
import { fetchTagData } from "../../../Redux/features/Brand/brandsSlice";
import BrandLogic from "../../Brands/brandLogic";
import SelectDropDownWithCheckBox from "../../../reuseableComponents/SelectDropDownWithCheckBox";
import useMultiSelect from "../../../hooks/useMultiSelect";

const ItemSalesFilter = ({
  onFilterDataChange,
  searchItems,
  setSearchRecord,
  debouncedValue,
  hide,
  allOptionSelected,
  setAllOptionSelected,
  categoryOptions,
  setCategoryOptions,
  TagList,
  setGetTagList,
  selectedBrandList,
  allBrandSelected,
  handleBrandClick,
  selectedTagList,
  allTagSelected,
  handleTagClick,
  selectedCategoryList,
  allCategorySelected,
  handleCategoryClick,
  brandList,
  setBrandList

}) => {
  const { LoginGetDashBoardRecordJson, LoginAllStore, userTypeData } =
    useAuthDetails();
  const { handleCoockieExpire, getUnAutherisedTokenMessage, getNetworkError } =
    PasswordShow();
  const [selectedOrderSource, setSelectedOrderSource] = useState("All");
  const [selectedOrderType, setSelectedOrderType] = useState("All");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("All");
  const [selectedTag, setSelectedTag] = useState("All");

  const [filteredData, setFilteredData] = useState({ category_id: "all" });
  const [items, setItems] = useState("");

  const handleOptionClick = (option, dropdown) => {
    switch (dropdown) {
      case "odersource":
        setSelectedOrderSource(option.title);

        break;
      case "ordertype":
        setSelectedOrderType(option.title);

        break;
      case "category":
        if (option === "All") {
          setSelectedCategory("All");

          setFilteredData({
            ...filteredData,
            category_id: "all",
            merchant_id: "",
            order_env: "",
            limit: "",
          });
        } else {
          const category_id = option.id;
          setSelectedCategory(option.title);

          setFilteredData({
            ...filteredData,
            category_id,
            merchant_id: "",
            order_env: "",
            limit: "",
          });
        }
        break;
      
        case "brand":
          if (option === "All") {
            setSelectedBrand("All");
            setFilteredData({
              ...filteredData,
              find_brand: "All",
            });
          } else {
            const brand = option.title;
            setSelectedBrand(option.title);
            setFilteredData({
              ...filteredData,
              find_brand:brand,
            });
          }
        break;
          case "tags":
          if (option === "All") {
            setSelectedTag("All");
            setFilteredData({
              ...filteredData,
              find_tag: "All",
            });
          } else {
            const tag = option.title;
            setSelectedTag(option.title);
            setFilteredData({
              ...filteredData,
              find_tag:tag,
            });
          }
      default:
        break;
    }
  };

  const handleSearchInputChange = (value) => {
    setSearchRecord(value);
  };

  
  const [loadingCategories, setLoadingCategories] = useState(true);
  const getBrandListSlice = useSelector((state) => state?.brandData);
 
  const dispatch = useDispatch();
  let data = {
    merchant_id: LoginGetDashBoardRecordJson?.data?.merchant_id,
    ...userTypeData,
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { token, ...dataNew } = data;
        const response = await axios.post(
          BASE_URL + TAXE_CATEGORY_LIST,
          dataNew,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Assuming the API response has a data property containing the category list
        const categoryList = response.data.result;

        // Extracting category IDs and view titles
        const mappedOptions = categoryList.map((category) => ({
          id: category.id,
          title: category.title,
        }));

        setCategoryOptions(mappedOptions);
        setLoadingCategories(false);
      } catch (error) {
        if (error.response.status == 401) {
          getUnAutherisedTokenMessage();
          handleCoockieExpire();
        }
        setLoadingCategories(false);
      }
    };
    const getBrandData = async () => {
      let merchant_id = LoginGetDashBoardRecordJson?.data?.merchant_id;
      let dataType0 = { merchant_id, type: 0, ...userTypeData };
      let dataType1 = { merchant_id, type: 1, ...userTypeData };
  
      try {
        const [responseType0, responseType1] = await Promise.all([
          dispatch(fetchTagData(dataType0)).unwrap(),
          dispatch(fetchTagData(dataType1)).unwrap(),
        ]);
  
        setGetTagList(responseType0); // Handle response for type: 0
        setBrandList(responseType1);
        console.log("Type 1 response:", responseType1); // Handle response for type: 1
      } catch (error) {
        if (error?.status == 401) {
          getUnAutherisedTokenMessage();
          handleCoockieExpire();
        }
      }
    };
    getBrandData();
    fetchData()
  }, []); // Fetch categories only once when the component mounts


  // const getBrandData = async () => {
  //   let merchant_id = LoginGetDashBoardRecordJson?.data?.merchant_id;
  //   let data = { merchant_id, type: 0, ...userTypeData };
  //   try {
  //     const response = await dispatch(fetchTagData(data)).unwrap();
  //     setGetTagList(response);
  //   } catch (error) {
  //     if (error?.status == 401) {
  //       getUnAutherisedTokenMessage();
  //       handleCoockieExpire();
  //     }
  //   }
  // };
  // useEffect(() => {
  //   if (!getBrandListSlice?.loading && !!getBrandListSlice?.tagData && getBrandListSlice?.tagData.length > 0) {
  //     setGetTagList(getBrandListSlice?.tagData);
  //   } else {
  //     setGetTagList([]);
  //   }
  // }, [getBrandListSlice?.loading]);
  // useEffect(() => {
  //   getBrandData();
  // }, []);

  useEffect(() => {
    onFilterDataChange(
      selectedOrderSource,
      selectedOrderType,
      selectedCategory,items,selectedBrand,selectedTag
    );
  }, [selectedOrderSource, selectedOrderType, selectedCategory,items,selectedBrand,selectedTag]);

  const orderSourceList = ["All", "Online Order", "Store Order"];
  const orderTypeList = ["All", "Pickup", "Delivery"];

  const handleSearch = () => {};

  const handleOptionClickCheck = (selectedItem) => {
    // console.log("selected item: ", selectedItem);
    // console.log("selected categories: ", selectedCategory);

    if (selectedItem === "All") {
      if (selectedCategory.length === categoryOptions.length) {
        setSelectedCategory([]); //deselectAll
        setAllOptionSelected(false);
      } else {
        const selectAll = categoryOptions.map((item) => ({
          ...item,
        }));

        setAllOptionSelected(true);
        setSelectedCategory(selectAll);
      }

      setFilteredData({
        ...filteredData,
        category_id: "All",
      });
    } else {
      const isSelected = selectedCategory.some(
        (item) => item.id === selectedItem.id
      ); // && item.isChecked
      // console.log("isSelected: ", isSelected);
      if (isSelected) {
        setSelectedCategory((prev) => {
          return prev.filter((item) => item.id !== selectedItem.id);
        });
        setAllOptionSelected(false);
      } else {
        const bool = selectedCategory.length === categoryOptions.length - 1;
        // console.log("bool: ", bool);
        setAllOptionSelected(bool);
        setSelectedCategory((prev) => [...prev, selectedItem]);
      }


      const updatedCategories = isSelected
        ? selectedCategory.filter((item) => item.id !== selectedItem.id)
        : [...selectedCategory, selectedItem];
      setFilteredData({
        ...filteredData,
        category_id: updatedCategories?.map((item) => item?.id).toString(),
      });
    }
  };

  return (
    <>
      <Grid container className="box_shadow_div">
        <Grid item xs={12}>
          {!hide && <CustomHeader>Item Sales</CustomHeader>}


          <Grid item xs={12}  sx={{ p: {xs:1,sm:2.5}}}>
              <InputTextSearch
                placeholder="Search by Items Name"
                value={searchItems}
                handleChange={handleSearchInputChange}
                handleSearchButton={handleSearch}
              />
            </Grid>

            {
              !debouncedValue ? (
                <>
                 <Grid container  sx={{ px:{xs:1,sm:2.5}, pt: 1 }}>
                    <Grid item xs={12}>
                      <div className="heading">Filter By</div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ px:{xs:1,sm:2.5}, pb: 2.5 }}>
                    {/* <Grid item xs={12} sm={6} md={4}>
                      <label
                        className="q-details-page-label"
                        htmlFor="orderSourceFilter"
                      >
                        Order Source
                      </label>
                      <SelectDropDown
                        listItem={orderSourceList.map((item) => ({ title: item }))}
                        title="title"
                        dropdownFor="odersource"
                        selectedOption={selectedOrderSource}
                        onClickHandler={handleOptionClick}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <label className="q-details-page-label" htmlFor="orderTypeFilter">
                        Order Type
                      </label>
                      <SelectDropDown
                        listItem={orderTypeList.map((item) => ({ title: item }))}
                        title="title"
                        dropdownFor="ordertype"
                        selectedOption={selectedOrderType}
                        onClickHandler={handleOptionClick}
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={4}>
                      <label className="q-details-page-label" htmlFor="orderTypeFilter">
                        Category
                      </label>
                      {/* <SelectDropDown
                        heading={"All"}
                        listItem={categoryOptions}
                        title="title"
                        dropdownFor="category"
                        selectedOption={selectedCategory}
                        onClickHandler={handleOptionClick}
                      /> */}
                      <SelectDropDownWithCheckBox
                          options={categoryOptions}
                          heading={"All"}
                          title="title"
                          label="Category"
                          onClickHandler={handleCategoryClick}
                          selectedOption={selectedCategoryList}
                          allOptionSelected={allCategorySelected}
                      />
                    </Grid>


                    <Grid item xs={12} sm={6} md={4}>
                      <label>Brands</label>
                      {/* <SelectDropDown
                        heading={"All"}
                        listItem={getBrandList}
                        title="title"
                        onClickHandler={handleOptionClick}
                        dropdownFor="brand"
                        selectedOption={selectedBrand}
                      /> */}

                    <SelectDropDownWithCheckBox
                      options={brandList}
                      heading={"All"}
                      title="title"
                      label="Brands"
                      onClickHandler={handleBrandClick}
                      selectedOption={selectedBrandList}
                      allOptionSelected={allBrandSelected}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <label>Tags</label>
                      {/* <SelectDropDown
                        listItem={TagList}
                        heading={"All"}
                        title="title"
                        onClickHandler={handleOptionClick}
                        dropdownFor="tags"
                        selectedOption={selectedTag}
                      /> */}

                    <SelectDropDownWithCheckBox
                      options={TagList}
                      heading={"All"}
                      title="title"
                      label="Tags"
                      onClickHandler={handleTagClick}
                      selectedOption={selectedTagList}
                      allOptionSelected={allTagSelected}
                    />
                    </Grid>
                  </Grid>
                </>
              ):("")
            }
        </Grid>
      </Grid>
    </>
  );
};

export default ItemSalesFilter;
