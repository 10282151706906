import React, { useEffect, useState } from "react";
import { fetchEmployeeListData } from "../../../Redux/features/EmployeeList/EmployeeListSlice";
import { useSelector, useDispatch } from "react-redux";
import "../../../Styles/EmployeeList/employeeList.css";
import { useAuthDetails } from "../../../Common/cookiesHelper";
import { Grid } from "@mui/material";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { SortTableItemsHelperFun } from "../../../helperFunctions/SortTableItemsHelperFun";
import sortIcon from "../../../Assests/Category/SortingW.svg";
import { SkeletonTable } from "../../../reuseableComponents/SkeletonTable";
import CustomHeader from "../../../reuseableComponents/CustomHeader";
import NoDataFound from "../../../reuseableComponents/NoDataFound";
import InputTextSearch from "../../../reuseableComponents/InputTextSearch";
const StyledTable = styled(Table)(({ theme }) => ({
  padding: 2, // Adjust padding as needed
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#253338",
    color: theme.palette.common.white,
    fontFamily: "CircularSTDMedium !important",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "CircularSTDBook !important",
  },
  [`&.${tableCellClasses.table}`]: {
    fontSize: 14,
    fontFamily: "CircularSTDBook !important",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {},
  "& td, & th": {
    border: "none",
  },
}));

const EmployeelistReport = ({
  hide,
  setCSVData,
  setCSVHeader,
  setCSVFileName,
}) => {
  const [employeeData, setAllEmployeeData] = useState([]);
  const [employeeDataFilter, setEmployeeDataFilter] = useState([]);
  const { LoginGetDashBoardRecordJson, LoginAllStore, userTypeData } =
    useAuthDetails();
  const [searchRecord, setSearchRecord] = useState("");

  const AllEmployeeListState = useSelector((state) => state.employeeDataList);
  const dispatch = useDispatch();
  let merchant_id = LoginGetDashBoardRecordJson?.data?.merchant_id;
  const tableRow = [
    { name: "employee_name", label: "Employee Name" },
    { name: "pin", label: "PIN" },
    { name: "contact", label: "Contact" },
    { name: "email", label: "Email" },
    { name: "address", label: "Address" },
  ];
  useEffect(() => {
    // setCSVHeader(tableRow?.map((row) => ({ label: row.label, key: row.name })));
    const fetchData = async () => {
      let data = {
        merchant_id,
        ...userTypeData,
      };

      if (data) {
        dispatch(fetchEmployeeListData(data));
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (
      !AllEmployeeListState.loading &&
      AllEmployeeListState.employeeListData
    ) {
      const updatedData = AllEmployeeListState?.employeeListData?.map(
        (data) => {
          const fullName = `${data?.f_name} ${data?.l_name}`;
          return { ...data, fullName: fullName };
        }
      );

      setAllEmployeeData(updatedData);
      setEmployeeDataFilter(updatedData);
      getExportData(updatedData);
      // console.log("employeeData?.length", updatedData.length);
      // if (updatedData.l_name > 0) {

      // } else {
      //   setCSVHeader([]);
      // }
    }
  }, [AllEmployeeListState.loading, AllEmployeeListState.employeeListData]);
  // ===============
  const getExportData = (employeeList) => {
    if (employeeList.length > 0) {
      setCSVFileName("employee_list");
      setCSVHeader(
        tableRow?.map((row) => ({ label: row.label, key: row.name }))
      );

      const formattedData =
        employeeList.length > 0
          ? employeeList.map((result) => {
              return {
                employee_name: result?.fullName ? result.fullName : "-",
                pin: result?.pin ? result.pin : "-",
                contact: result?.phone ? result.phone : "-",
                email: result?.email ? result.email : "-",
                address: [
                  result?.address,
                  result?.city,
                  result?.state,
                  result?.zipcode,
                ]
                  .filter(Boolean) // Remove falsy values
                  .join(", "), // Join with a comma and space
              };
            })
          : [];
      setCSVData(formattedData);
    } else {
      setCSVHeader([]);
      setCSVData([]);
      setCSVFileName("");
    }
  };
  // ===============

  const [sortOrder, setSortOrder] = useState("asc");
  const sortByItemName = (type, name) => {
    const { sortedItems, newOrder } = SortTableItemsHelperFun(
      employeeData,
      type,
      name,
      sortOrder
    );
    setAllEmployeeData(sortedItems);
    setSortOrder(newOrder);
  };
  const handleSearchInputChange = (value) => {
    setSearchRecord(value);
    let data = employeeDataFilter.filter((employeeByName) =>
      (
        (employeeByName?.fullName?.toLowerCase() || "") +
        (employeeByName?.phone?.toLowerCase() || "") +
        (employeeByName?.email?.toLowerCase() || "")
      ).includes(value.toLowerCase())
    );
    setAllEmployeeData(data);
  };
  return (
    <>
      <Grid
        container
        className="box_shadow_div"
        sx={{ mt: `${hide ? 0 : 2.5}`, py: 2.5 }}
      >
        <Grid item xs={12} sx={{ px: { xs: 1, sm: 2.5 } }}>
          <InputTextSearch
            className=""
            type="text"
            value={searchRecord}
            handleChange={handleSearchInputChange}
            placeholder="Search by Employee Name"
            autoComplete="off"
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ mt: `${hide ? 0 : 2.5}` }}
        className="box_shadow_div"
      >
        {!hide ? <CustomHeader>Employee List </CustomHeader> : ""}
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              {AllEmployeeListState.loading ? (
                <SkeletonTable
                  columns={[
                    "Employee Name",
                    "PIN",
                    "Contact",
                    "Email",
                    "Address",
                  ]}
                />
              ) : (
                <TableContainer
                  className="custom-scroll-hidden"
                  sx={{ maxHeight: "80vh" }}
                >
                  <StyledTable
                    stickyHeader
                    sx={{ minWidth: 700 }}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <StyledTableCell>
                        <button
                          className="flex items-center"
                          onClick={() => sortByItemName("str", "fullName")}
                        >
                          <p className="whitespace-nowrap">Employee Name</p>
                          <img src={sortIcon} alt="" className="pl-1" />
                        </button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <button
                          className="flex items-center"
                          onClick={() => sortByItemName("num", "pin")}
                        >
                          <p>PIN</p>
                          <img src={sortIcon} alt="" className="pl-1" />
                        </button>
                      </StyledTableCell>
                      <StyledTableCell>Contact</StyledTableCell>
                      <StyledTableCell>
                        <button
                          className="flex items-center"
                          onClick={() => sortByItemName("str", "email")}
                        >
                          <p>Email</p>
                          <img src={sortIcon} alt="" className="pl-1" />
                        </button>
                      </StyledTableCell>
                      <StyledTableCell>Address</StyledTableCell>
                    </TableHead>
                    <TableBody>
                      {employeeData && employeeData?.length >= 1
                        ? employeeData?.map((employee, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell>
                                <p>{employee?.fullName}</p>
                              </StyledTableCell>
                              <StyledTableCell>
                                <p>{employee?.pin}</p>
                              </StyledTableCell>
                              <StyledTableCell>
                                <p>{employee?.phone}</p>
                              </StyledTableCell>
                              <StyledTableCell>
                                <p>{employee?.email}</p>
                              </StyledTableCell>
                              <StyledTableCell>
                                <p>
                                  {" "}
                                  {employee
                                    ? [
                                        employee.address,
                                        employee.city,
                                        employee.state,
                                        employee.zipcode,
                                      ]
                                        .filter(Boolean)
                                        .join(", ")
                                    : ""}
                                </p>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        : ""}
                    </TableBody>
                  </StyledTable>
                  {!employeeData?.length && <NoDataFound />}
                </TableContainer>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EmployeelistReport;
