import React from "react";


const AttriContent = () => {
  return (
    <>
      <div className="box">
        <div className="">
        <div className="q-attributes-top-detail-section">
          <li> The added Attributes cannot be deleted.</li>
        </div>
        </div>
      </div>
    </>
  );
};

export default AttriContent;
