import React from 'react';
import SettingStoreOption from './SettingStoreOption';
import { Grid } from '@mui/material';

const MainStoreOption = () => {
    return (
      <>
      <Grid  style={{paddingBottom:"120px "}}>
        <SettingStoreOption />
        </Grid>
      </>
    )
  }
  
  export default MainStoreOption